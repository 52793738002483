<template>
    <div v-for="(item, index) in imgLisst" :key="index" class="imglistCotainer" :style="{marginRight}"
        @click="$openLink(item.link)">
        <img :src="item.src" alt="" style="width: 0.8rem; height: auto;"  :style="{filter: flag?'':'brightness(0.5)'}"/>
    </div>
</template>

<script setup>
import { reactive } from "vue";

const imgLisst = data.imgList;
const props = defineProps({
  marginRight:{
    default: "0px"
  },
  flag:{
    default: true
  }
})
</script>

<style lang="scss" scoped>
.imglistCotainer {
  display: inline-flex;
  border-radius: 50%;
  border: 0.0625rem solid var(--nav-color);
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
</style>
<template>
  <div id="scalingPlans">
    <h1 class="title">{{ $trans('Your Growth Opportunities') }}</h1>
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item
        :name="index"
        v-for="(item, index) in table"
        :key="index"
      >
        <template #title>
          <div class="tab">
            <div class="a">
              <span>{{ $trans(item.label1) }}</span>
              <img :src="activeName === index ? $getImg(item.activeImg):$getImg(item.img)">
            </div>
            <div class="b">
              <span>{{ $trans(item.label2) }}</span>
            </div>
          </div>
        </template>
        <template #icon="{ isActive }">
          <img
            :src="isActive ? $getImg('icon-eval-arrow_rotated.webp') : $getImg('icon-eval-arrow.webp')"
            class="tabimg"
          />
        </template>
        <div class="tab">
          <div class="a"></div>
          <div class="b column-layout">
            <ul>
              <li v-for="(i, j) in item.content" :key="j">
                {{ i }}
              </li>
            </ul>
            <a class="start-challenge"  @click="$openLink()">{{ $trans('Start Challenge') }}</a>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
let path = "Opportunities/"
let sufix = ".webp"
const activeName = ref(2);
const table = reactive([
  {
    label1: "After 1-2 Scale-Ups",
    img: `${path}1${sufix}`,
    activeImg: `${path}1.1${sufix}`,
    label2: "Verified Trader",
    content: [
      "Normal Scaling Plan",
      "25% Increase | Every 3 Months | 10% Profit | 2/3 Months Profitable"
    ]
  },
  {
    label1: "After 3-5 Scale-Ups",
    img: `${path}2${sufix}`,
    activeImg: `${path}2.2${sufix}`,
    label2: "Gold Level",
    content: [
      "Normal Scaling Plan",
      "Increase Drawdown",
      "Max Loss increase to 12%",
      "Max daily loss increase to 6%"
    ]
  },
  {
    label1: "On 6th Scale-Up",
    img: `${path}3${sufix}`,
    activeImg: `${path}3.3${sufix}`,
    label2: "Platinum Level",
    content: [
      "Normal Scaling Plan",
      "Exclusive access to FT trading floors (Dubai or Miami)",
      "Gain a seat at the FundingTraders Dubai or Miami office",
      "Join the team of senior traders in a physical office",
      "Fixed salary + quarterly performance bonuses",
      "Join exclusive seminars by senior traders",
      "Access to professional trading psychologists and performance coaches"
    ]
  }
]);
</script>

<style lang="scss" scoped>
#scalingPlans{
  @include padding-index;
}
.title {
  font-size: 4.8rem;
  margin:0;
  margin-bottom: 2rem;
}

.tab {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding:0.4rem 0;

  .a {
    flex-basis: 40%;
    text-align: left;
    color: #d7d7d7;
    display: flex;
    justify-content: space-between;
    align-items:center;
    img{
      width: 3.25rem;
      margin-right:2rem;
    }
  }

  .b {
    flex: 1;
    display: flex;
    justify-content: space-between;

    span {
      text-align: left;
      color: white;
      font-size: 2rem;
    }
  }

  .column-layout {
    flex-direction: column;
    align-items: flex-start;
  }
  a {
    margin-top:6rem;
  }
}
::v-deep .el-collapse {
  border-color:#515151;
}
::v-deep .el-collapse-item__header {
  background: transparent;
  color: var(--nav-color);
   font-size: 1rem;
    height:auto;
  border-color:#515151;

}

::v-deep .el-collapse-item__wrap {
  background: transparent;
  color: var(--nav-color);
}

::v-deep .el-collapse-item__content {
  color: inherit;
  font-size:0.8rem;
}

.tabimg {
  width: 0.6rem;
}

ul {
  list-style: none;
  padding-left: 0;
}

li:nth-of-type(1)::before {
  content: "✓";
  margin-right: 1rem;
}

li::before {
  content: "+";
  margin-right: 1rem;
}

.start-challenge {
  color: white;
  font-weight: 600;
  text-decoration: underline;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  ::v-deep .el-collapse-item__header {
    height: auto;
  }
  ::v-deep .el-collapse {
    border-top: none;
  }
  .title {
    font-size: 2rem;
  }

  .tab {
    flex-direction: column;
    align-items: flex-start;
    .a {
      color: #8c8c8c;
      font-size: 0.8rem;
     flex-direction: row-reverse !important; 
     align-items:center;
     img{
      width:1.5rem;
      margin-right:1rem;
     }
    }
    .a,
    .b {
      flex-basis: 100%;
      text-align: left;
      display: flex;
      flex-direction: column;
    }

    .b span {
      font-size: 1.5rem;
    }
  }

  ul {
    margin-top: 1rem;
  }

  .start-challenge {
    font-size: 1rem;
  }
}
</style>

<template>
    <div class="tradeRoot">
        <customerNav></customerNav>
        <Best
        subTitle="Funded Trader Programs"
        title="Trader Funding Programs"
        description1="Start forex trading journey with a legitimate prop firm and secure up to $1M+ trading capital. With multiple prop funding opportunities, you can manage institutional-level capital to maximize your buying power, trading positions, and profit potential."
        description2="Continue trading on a live funded account with industry leaders and receive weekly payouts with a competitive up to 100% profit split. "
        img="phone.webp"
        :show="false"
        ></Best>
        <Firm></Firm>
        <traderc></traderc>
        <traderc
        title="Funded Trading Accounts"
        description1="Choose between multiple funded trading challenge accounts to trade with a capital that suits your experience level. From $5,000 to $500,000, we offer a wide range of funding options to support all clients - beginners, seasoned, and experienced traders. "
        description2="Leverage these opportunities to boost your trading career and trade with bigger capital. Choose a funding size that suits your style and complete our evaluation to trade with a live account. If you start small, you can showcase consistent performance and qualify for capital scaling opportunities to manage up to $1M+ funded accounts."
        img="eval.webp"
        ></traderc>
        <traderc
        title="Prop Trading Career Opportunities"
        description1="Explore incredible career opportunities as a prop trader and unlock your potential to earn consistent profits. Sign up for funding programs and leverage profitable strategies to trade among industry experts. With specialized trader funding opportunities, we accommodate all strategies and experience levels - building a growth-oriented community for traders."
        description2="With these career opportunities, you are no longer limited by capital constraints. Select a funding size that suits your style and hold bigger, rewarding, and more competitive positions in the market. Take advantage of a competitive 1:100 forex leverage to maximize your buying power and increase overall profits."
        img="view.webp"
        ></traderc>
        <customerBottom></customerBottom>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.tradeRoot{
	overflow-x: hidden;
	background:url('@/assets/banner-trader-funding.webp');
	background-repeat: no-repeat;
	    background-size: auto auto;
	    background-position: top right;
}
</style>
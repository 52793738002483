<template>
	<div class="about-section">
		<div class="bg">
			<h1 v-if="isMobile">ABOUT FUNDING TRADERS</h1>
			<!-- Header Section -->
			<div class="header">
				<div class="header-left">
					<p>OUR PROP FIRM - TRADERS WITH REAL-WORLD EXPERIENCE OF MORE THAN 2 DECADES AND ACCESS TO
						INSTITUTIONAL
						RESOURCES</p>
				</div>
				<div class="header-right">
					<video :src="$getImg('intro.mp4')" controls :poster="$getImg('intro.webp')" playsinline
						preload></video>
				</div>
			</div>

			<!-- Title and Description Section -->
			<div>
				<h1 v-if="!isMobile">ABOUT FUNDING TRADERS</h1>
				<p class="sub-title">
					ESTABLISHED IN 2023, FUNDINGTRADERS EMBARKED ON A MISSION TO IDENTIFY AND SUPPORT CONSISTENTLY
					PROFITABLE
					TRADERS TO FOSTER THE FIRM'S CAPITAL GROWTH.
				</p>
				<p class="label-title">
					With a global footprint that spans across key financial hotspots like Miami, Dubai, and the United
					Kingdom, we
					are seamlessly integrated into the world’s leading financial market.
				</p>
			</div>
		</div>

		<!-- Statistics Section -->
		<div class="infoP">
			<div class="info">
				<div v-for="(item, index) in info" :key="index" class="item">
					<span class="label">{{ item.label }}</span>
					<span class="value">{{ item.value }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		useStore
	} from "@/store";
	import {
		reactive
	} from "vue";
	const isMobile = useStore().isMobile;
	const info = reactive([{
			label: "Total Payouts in 2023",
			value: "$4.7M+"
		},
		{
			label: "Funded Traders",
			value: "5.6K+"
		},
		{
			label: "Avg. Payout Time",
			value: "24 Hours"
		}
	]);
</script>

<style lang="scss" scoped>
	.about-section {
		.bg {
			background: url("@/assets/banner-about-us.webp");
			background-repeat: no-repeat;
			background-size: auto auto;
			background-position: top right;
			@include padding-components;
		}

		color: #d7d7d7;

		.header {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			margin-top: 2rem;

			.header-left {
				flex: 1;
				padding-right: 2rem;

				p {
					font-size: 0.8rem;
					line-height: 1;
					text-align: left;
					width: 60%;
					margin: 0;
				}
			}

			.header-right {
				flex-basis: 40%;
				text-align: right;

				video {
					width: 50%;
					border-radius: 0.5rem;
				}
			}
		}

		h1 {
			font-size: 6.5rem;
			color: white;

			margin: 0 0 1rem;
		}

		.sub-title {
			font-size: 1.5rem;
			font-weight: bold;
			margin: 4rem 0 0.8rem;
			text-indent: 50%;
			margin-left: 40%;
		}

		.label-title {
			font-size: 1rem;
			margin-bottom: 2rem;
			margin-left: 40%;
			margin-right: 30%;
			font-size: 0.8rem;
			color: #8c8c8c;
		}

		.infoP {
			@include padding-components;
		}

		.info {
			display: flex;
			justify-content: space-around;
			border-top: 0.0625rem solid #515151;
			margin-top: 2rem;
			height: 6rem;

			.item {
				flex: 1;
				padding: 1rem;
				display: flex;

				&:not(:last-child) {
					border-right: 0.0625rem solid #515151;
				}

				.label {
					color: var(--nav-color);
					display: block;
					margin-bottom: 0.5rem;
					font-size: 0.7rem
				}

				.value {
					color: #c1c1c1;
					font-weight: bold;
					font-size: 1.5rem;
					align-self: flex-end;
					flex: 1;
					text-align: right;
					@include title-set;
				}
			}
		}
	}

	/* Responsive Styles for Mobile Devices */
	@media (max-width: 768px) {
		.bg {
			@include mobile-padding-components;
		}

		.header {
			flex-direction: column;

			.header-left {
				padding-right: 0 !important;

				p {
					font-size: 0.7rem !important;
					width:100% !important;
				}
			}

			.header-right {
				flex-basis: 100%;
				margin-top: 1rem;

				video {
					width: 100% !important;
				}
			}
		}

		h1 {
			font-size: 2.5rem !important;
			text-align: left !important;
		}

		.sub-title {
			font-size: 1.2rem;
			text-align: left !important;
			padding: 0;
			margin-left: 0 !important;
			text-indent: 0 !important;
			font-weight: 500 !important;
		}

		.label-title {
			font-size: 1rem;
			text-align: left !important;
			padding: 0;
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		.infoP{
			@include mobile-padding-components;
		}
		.info {
			flex-direction: column;
			align-items: center;
			padding-top: 0;
			border-top: none !important;
			height: auto !important;
			margin-top: 0 !important;
			.item {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-right: none !important;
				border-top: 0.0625rem solid #515151;
				padding: 0.5rem 0 !important;

				&:last-child {
					border-bottom: 0.0625rem solid #515151;
				}

				.label {
					font-size: 0.9rem;
					margin: 0
				}

				.value {
					font-size: 2rem;
				}
			}
		}
	}
</style>
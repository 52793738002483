import Intercom from '@intercom/messenger-js-sdk';
Intercom({
    app_id: 'lw7y7q9k'    
  });
// let body = document.getElementsByTagName('body')[0]
// let img = document.createElement('img')
// img.src = require('@/assets/globalBg.png')
// img.style.width = '5rem'
// img.style.position = 'fixed'
// img.style.bottom = '3rem'
// img.style.right = '0'
// img.style.zIndex = 10000
// body.appendChild(img)
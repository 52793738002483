import { defineStore } from 'pinia';

export const useStore = defineStore('index', {
  state: () => ({
    isMobile: false,
  }),
  actions: {
    setisMobile(e) {
      this.isMobile = e
    }
  },
});

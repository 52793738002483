<template>
  <div class="affiliate-page">
    <header class="header">
      <h1>PROP TRADING AFFILIATE</h1>
    </header>
    <div class="flex-Box">
      <section class="features">
        <div class="feature-item">
          <span>✔</span> 60-DAY COOKIE
        </div>
        <div class="feature-item">
          <span>✔</span> ALL MARKETING MATERIALS
        </div>
        <div class="feature-item">
          <span>✔</span> 10% PER SALE + SCALE UP TO 20%
        </div>
      </section>
      <subBtn text="Become and Affiliate" marginTop="0px" class="subBtn"></subBtn>
    </div>
    <section class="commission">
      <h2>BEST COMMISSION RATES IN THE INDUSTRY</h2>
      <ul class="commission-list">
        <li>1 - Start 10%</li>
        <li>2 - Advance 15%</li>
        <li>3 - Pro 20%</li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: "AffiliatePage"
};
</script>

<style scoped lang="scss">
.affiliate-page {
  color: #d7d7d7;
  @include padding-components;
}

.header {
  margin-bottom: 1.25rem;

  h1 {
    font-size: 7rem;
    font-weight: bold;
    margin-bottom: 0.625rem;
    color:white;
  }
}

.flex-Box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.features {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  font-size: 0.8rem;
  border-radius: 0.625rem;
}

.feature-item {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size:0.6rem;
  flex-basis: 25%;
}

.feature-item span {
  margin-bottom: 0.5rem;
}

.commission {
  // margin-top: 2.5rem;
  background: var(--black);
  width: 100vw;
  @include padding-components;
  padding-bottom:0 !important;

  h2 {
    font-size: 5rem;
    line-height:0.9;
    width: 100%;
    font-weight: bold;
    margin-bottom: 1.25rem;
    color:white;
  }

  .commission-list {
    list-style: none;
    font-size: 0.8rem;
    text-align: left;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.625rem;
    max-width: 25rem;
    padding-left:0;
    font-size:0.6em;
  }
}

.subBtn {
  width: 100%;
  max-width: 13rem;
}

@media (max-width: 768px) {
  .affiliate-page{
    padding: 2rem 0.25rem;
  }
  .header h1 {
    font-size: 3rem;
    margin-top:0;
    line-height:0.8;
  }
  *{
    text-align: left !important;
  }
  .features {
    padding: 0;
    margin-top:2rem;
    flex-wrap: nowrap;
  }
.subBtn{
  width:100%;
  max-width:100%;
  margin-top:3rem !important;
}
  .flex-Box {
    flex-direction: column;
  }
.commission{
	padding:2rem 0.25rem;
}
  .commission h2 {
    font-size: 2.5rem;
  }

  .commission-list {
    font-size: 0.8rem !important;
  }
}
</style>

<template>
    <div class="tradingroot">
        <customerNav></customerNav>
        <Best></Best>
        <Choose></Choose>
        <Succeeding></Succeeding>
        <Join></Join>
        <customerBottom></customerBottom>
    </div>
</template>

<script>
</script>

<style lang="scss" scoped>
	.tradingroot{
		overflow-x: hidden;
		background-image: url('@/assets/trading.webp');
		background-repeat: no-repeat;
		background-size: auto auto;
		background-position: top right;
	}
</style>
<template>
  <div class="evaluations" id="pricing">
    <h1 class="evaluations-title">{{ $trans('Our Evaluations') }}</h1>
    <div class="evaluation-container">
      <!-- Program Selection -->
      <div class="program-tabs">
        <div class="program-tab-left" @click="activeProp = 0">
          <div class="tab-step" :class="{active:!activeProp}">[1-STEP]</div>
          <span class="tab" :class="{active:!activeProp}">RAPID PROGRAM</span>
        </div>
        <div class="program-tab-right" @click="activeProp = 1">
          <div class="tab-step" :class="{active:activeProp}">[2-STEP]</div>
          <span class="tab" :class="{active:activeProp}">CLASSIC FUNDING</span>
        </div>
      </div>
      <!-- Funding Amount Selection -->
      <div class="funding-amounts">
        <span :class="{active:activeTab == index}" @click="activeTab=index" v-for="(item,index) in spanTab" :key="index">${{item}}k</span>
      </div>
      <!-- Table -->
      <div class="tab-content checkPhone">
        <div class="a"></div>
        <div class="b">
          <span>{{ $trans('Step 1 Challenge') }}</span>
          <span>{{ $trans('Step 2 Verification') }}</span>
          <span>{{ $trans('Funded') }}</span>
        </div>
      </div>
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item
          v-for="(item, index) in tab[activeProp][activeTab].content"
          :name="index"
          :key="index"
        >
          <template #title>
            <div class="tab-content">
              <div class="a">{{ item.label1 }}</div>
              <div class="b">
                <template v-if="isMobile">
                  <span>{{ transSpan(item) }}</span>
                </template>
                <template v-else>
                  <span>{{ item.label2 }}</span>
                <span>{{ item.label3 }}</span>
                <span>{{ item.label4 }}</span>
                </template>
              </div>
            </div>
          </template>
          <template #icon="{ isActive }">
            <img
              :src="isActive ? $getImg('icon-eval-arrow_rotated.webp') : $getImg('icon-eval-arrow.webp')"
              class="tabimg"
            />
          </template>
          {{ item.content }}
        </el-collapse-item>
      </el-collapse>
      <subBtn :text="`Get Funded Fee $${spanTab[activeTab]}`" :imgShow="false"></subBtn>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useStore } from "@/store";
const activeName = ref("1");
const activeProp = ref("0");
const activeTab = ref("0");
const isMobile = useStore().isMobile;
const spanTab = [5,10,25,50,100,200,350,500];
const tab = reactive([
  [
    {
      laebl: "5",
      content: [
        {
          label1: "Profit Target",
          label2: "10% - $1,000 (static)",
          label3: "-",
          label4: "-",
          content:
            "Achieve a 10% profit target during the challenge phase and unlock a no-profit-target funded phase with our 1-step account. Focus on growth, not restrictions, as you transition to real-market success."
        },
        {
          label1: "Daily Drawdown",
          label2: "4% - $200 (static)",
          label3: "-",
          label4: "4% - $200 (static)",
          content:
            "Stay on track with a 4% daily loss limit to safeguard your trading journey. Your equity or balance must not drop more than 4% below the starting day's equity, ensuring controlled risk management. For example, if you start the day with $5,400, your allowable drawdown threshold for that day is $5,200."
        },
        {
          label1: "Max Drawdown",
          label2: "5% - $250 (trailing)",
          label3: "-",
          label4: "5% - $250 (trailing)",
          content:
            "With our 1-step account, your maximum loss is capped at 5% of your initial balance, adjusting as your account grows. For example, starting with $5,000, if your balance reaches $5,500, your max overall loss is set at $5,250. This dynamic loss limit protects your capital while giving you room to grow as your account balance increases."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "-",
          label4: "1",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "-",
          label4: "$3/lot",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "10",
      content: [
        {
          label1: "Profit Target",
          label2: "10% - $1,000 (static)",
          label3: "5%-$500 (static)",
          label4: "-",
          content:
            "Elevate your trading journey with our 2-step account: achieve a 10% profit target in the first phase and a 5% target in the second phase. Transition seamlessly to the funded phase with no profit targets, allowing you to focus on maximizing returns. Experience a structured path to success with clear milestones and the freedom to grow."
        },
        {
          label1: "Daily Drawdown",
          label2: "5% - $500 (static)",
          label3: "5% - $500 (static)",
          label4: "5% - $500 (static)",
          content:
            "Protect your trading capital with a 5% daily loss limit, ensuring your equity remains secure. Your equity or balance must not fall more than 5% below the starting day's balance, keeping risk in check. For instance, if you begin the day with $11,000, your maximum allowable loss for that day is $10,500."
        },
        {
          label1: "Max Drawdown",
          label2: "10% - $1,000 (static)",
          label3: "10% - $1,000 (static)",
          label4: "10% - $1,000 (static)",
          content:
            "Maintain control with a fixed 10% maximum loss limit, ensuring your equity doesn't drop more than 10% relative to the initial account balance. This safeguard does not trail higher as profits increase, protecting your capital consistently. For example, with an initial balance of $10,000, the maximum allowable loss is $9,000."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "Unlimited",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "1",
          label4: "1",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "2%",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "0",
          label4: "$3/lot",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "1:100",
          label4: "1:100",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "✓",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "✓",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "20%",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "25",
      content: [
        {
          label1: "Profit Target",
          label2: "10% - $2,500 (static)",
          label3: "-",
          label4: "-",
          content:
            "Elevate your trading journey with our 2-step account: achieve a 10% profit target in the first phase and a 5% target in the second phase. Transition seamlessly to the funded phase with no profit targets, allowing you to focus on maximizing returns. Experience a structured path to success with clear milestones and the freedom to grow."
        },
        {
          label1: "Daily Drawdown",
          label2: "4% - $1,000 (static)",
          label3: "-",
          label4: "4% - $1,000 (static)",
          content:
            "Protect your trading capital with a 5% daily loss limit, ensuring your equity remains secure. Your equity or balance must not fall more than 5% below the starting day's balance, keeping risk in check. For instance, if you begin the day with $11,000, your maximum allowable loss for that day is $10,500."
        },
        {
          label1: "Max Drawdown",
          label2: "5% - $1,250 (trailing)",
          label3: "-",
          label4: "5% - $1,250 (trailing)",
          content:
            "Maintain control with a fixed 10% maximum loss limit, ensuring your equity doesn't drop more than 10% relative to the initial account balance. This safeguard does not trail higher as profits increase, protecting your capital consistently. For example, with an initial balance of $10,000, the maximum allowable loss is $9,000."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "-",
          label4: "1",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "-",
          label4: "$3/lot",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "50",
      content: [
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "-",
          label4: "1",
          content:
            "Elevate your trading journey with our 2-step account: achieve a 10% profit target in the first phase and a 5% target in the second phase. Transition seamlessly to the funded phase with no profit targets, allowing you to focus on maximizing returns. Experience a structured path to success with clear milestones and the freedom to grow."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Protect your trading capital with a 5% daily loss limit, ensuring your equity remains secure. Your equity or balance must not fall more than 5% below the starting day's balance, keeping risk in check. For instance, if you begin the day with $11,000, your maximum allowable loss for that day is $10,500."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "-",
          label4: "$3/lot",
          content:
            "Maintain control with a fixed 10% maximum loss limit, ensuring your equity doesn't drop more than 10% relative to the initial account balance. This safeguard does not trail higher as profits increase, protecting your capital consistently. For example, with an initial balance of $10,000, the maximum allowable loss is $9,000."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "1:100",
          label4: "1:100",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
      ]
    },
    {
      laebl: "100",
      content: [
        {
          label1: "Profit Target",
          label2: "10% - $10,000 (static)",
          label3: "-",
          label4: "-",
          content:
            "Elevate your trading journey with our 2-step account: achieve a 10% profit target in the first phase and a 5% target in the second phase. Transition seamlessly to the funded phase with no profit targets, allowing you to focus on maximizing returns. Experience a structured path to success with clear milestones and the freedom to grow."
        },
        {
          label1: "Daily Drawdown",
          label2: "4% - $4,000 (static)",
          label3: "-",
          label4: "4% - $4,000 (static)",
          content:
            "Protect your trading capital with a 5% daily loss limit, ensuring your equity remains secure. Your equity or balance must not fall more than 5% below the starting day's balance, keeping risk in check. For instance, if you begin the day with $11,000, your maximum allowable loss for that day is $10,500."
        },
        {
          label1: "Max Drawdown",
          label2: "5% - $5,000 (trailing)",
          label3: "-",
          label4: "5% - $5,000 (trailing)",
          content:
            "Maintain control with a fixed 10% maximum loss limit, ensuring your equity doesn't drop more than 10% relative to the initial account balance. This safeguard does not trail higher as profits increase, protecting your capital consistently. For example, with an initial balance of $10,000, the maximum allowable loss is $9,000."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "-",
          label4: "1",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "-",
          label4: "$3/lot",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "200",
      content: [
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "-",
          label4: "1",
          content:
            "Elevate your trading journey with our 2-step account: achieve a 10% profit target in the first phase and a 5% target in the second phase. Transition seamlessly to the funded phase with no profit targets, allowing you to focus on maximizing returns. Experience a structured path to success with clear milestones and the freedom to grow."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Protect your trading capital with a 5% daily loss limit, ensuring your equity remains secure. Your equity or balance must not fall more than 5% below the starting day's balance, keeping risk in check. For instance, if you begin the day with $11,000, your maximum allowable loss for that day is $10,500."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "-",
          label4: "$3/lot",
          content:
            "Maintain control with a fixed 10% maximum loss limit, ensuring your equity doesn't drop more than 10% relative to the initial account balance. This safeguard does not trail higher as profits increase, protecting your capital consistently. For example, with an initial balance of $10,000, the maximum allowable loss is $9,000."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
      ]
    },
    {
      laebl: "350",
      content: [
        {
          label1: "Profit Target",
          label2: "10%-$35,000 (static)",
          label3: "-",
          label4: "-",
          content:
            "Elevate your trading journey with our 2-step account: achieve a 10% profit target in the first phase and a 5% target in the second phase. Transition seamlessly to the funded phase with no profit targets, allowing you to focus on maximizing returns. Experience a structured path to success with clear milestones and the freedom to grow."
        },
        {
          label1: "Daily Drawdown",
          label2: "5%-$17,500 (static)",
          label3: "-",
          label4: "5%-$250 (static)",
          content:
            "Protect your trading capital with a 5% daily loss limit, ensuring your equity remains secure. Your equity or balance must not fall more than 5% below the starting day's balance, keeping risk in check. For instance, if you begin the day with $11,000, your maximum allowable loss for that day is $10,500."
        },
        {
          label1: "Max Drawdown",
          label2: "10%-$35,000 (trailing)",
          label3: "-",
          label4: "10%-$250 (static)",
          content:
            "Maintain control with a fixed 10% maximum loss limit, ensuring your equity doesn't drop more than 10% relative to the initial account balance. This safeguard does not trail higher as profits increase, protecting your capital consistently. For example, with an initial balance of $10,000, the maximum allowable loss is $9,000."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "25",
          label3: "-",
          label4: "25",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "no",
          label3: "-",
          label4: "$3/lot",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "100%",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "500",
	  content: [
	    {
	      label1: "Profit Target",
	      label2: "10%-$35,000 (static)",
	      label3: "-",
	      label4: "-",
	      content:
	        "Elevate your trading journey with our 2-step account: achieve a 10% profit target in the first phase and a 5% target in the second phase. Transition seamlessly to the funded phase with no profit targets, allowing you to focus on maximizing returns. Experience a structured path to success with clear milestones and the freedom to grow."
	    },
	    {
	      label1: "Daily Drawdown",
	      label2: "5%-$17,500 (static)",
	      label3: "-",
	      label4: "5%-$250 (static)",
	      content:
	        "Protect your trading capital with a 5% daily loss limit, ensuring your equity remains secure. Your equity or balance must not fall more than 5% below the starting day's balance, keeping risk in check. For instance, if you begin the day with $11,000, your maximum allowable loss for that day is $10,500."
	    },
	    {
	      label1: "Max Drawdown",
	      label2: "10%-$35,000 (trailing)",
	      label3: "-",
	      label4: "10%-$250 (static)",
	      content:
	        "Maintain control with a fixed 10% maximum loss limit, ensuring your equity doesn't drop more than 10% relative to the initial account balance. This safeguard does not trail higher as profits increase, protecting your capital consistently. For example, with an initial balance of $10,000, the maximum allowable loss is $9,000."
	    },
	    {
	      label1: "Trading Period",
	      label2: "Unlimited",
	      label3: "-",
	      label4: "Unlimited",
	      content:
	        "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
	    },
	    {
	      label1: "Minimum Tracking Days",
	      label2: "25",
	      label3: "-",
	      label4: "25",
	      content:
	        "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
	    },
	    {
	      label1: "Max loss per trade",
	      label2: "2%",
	      label3: "-",
	      label4: "2%",
	      content:
	        "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
	    },
	    {
	      label1: "Commissions",
	      label2: "no",
	      label3: "-",
	      label4: "$3/lot",
	      content:
	        "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
	    },
	    {
	      label1: "Trading Leverage",
	      label2: "1:100",
	      label3: "-",
	      label4: "1:100",
	      content:
	        "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
	    },
	    {
	      label1: "Profit Split Up to",
	      label2: "100%",
	      label3: "-",
	      label4: "100%",
	      content:
	        "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
	    },
	    {
	      label1: "Weekend, Overnight, News, Swing, EAs",
	      label2: "✓",
	      label3: "-",
	      label4: "✓",
	      content:
	        "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
	    },
	    {
	      label1: "Fee Refund",
	      label2: "✓",
	      label3: "-",
	      label4: "✓",
	      content:
	        "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
	    },
	    {
	      label1: "Reset Discount",
	      label2: "20%",
	      label3: "-",
	      label4: "20%",
	      content:
	        "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
	    }
	  ]
    }
  ],
  [
    {
      laebl: "5",
      content: [
        {
          label1: "Profit Target",
          label2: "10% - $500 (static)",
          label3: "-",
          label4: "-",
          content:
            "Achieve a 10% profit target during the challenge phase and unlock a no-profit-target funded phase with our 1-step account. Focus on growth, not restrictions, as you transition to real-market success."
        },
        {
          label1: "Daily Drawdown",
          label2: "4% - $200 (static)",
          label3: "-",
          label4: "4% - $200 (static)",
          content:
			"Stay on track with a 4% daily loss limit to safeguard your trading journey. Your equity or balance must not drop more than 4% below the starting day's equity, ensuring controlled risk management. For example, if you start the day with $5,400, your allowable drawdown threshold for that day is $5,200."
        },
        {
          label1: "Max Drawdown",
          label2: "5% - $250 (trailing)",
          label3: "-",
          label4: "5% - $250 (trailing)",
          content:
			"With our 1-step account, your maximum loss is capped at 5% of your initial balance, adjusting as your account grows. For example, starting with $5,000, if your balance reaches $5,500, your max overall loss is set at $5,250. This dynamic loss limit protects your capital while giving you room to grow as your account balance increases."        
		},
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "-",
          label4: "1",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "0",
          label4: "$3/lot",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "10",
      content: [
        {
          label1: "Profit Target",
          label2: "10% - $1,000 (static)",
          label3: "-",
          label4: "-",
          content:
			"Achieve a 10% profit target during the challenge phase and unlock a no-profit-target funded phase with our 1-step account. Focus on growth, not restrictions, as you transition to real-market success."        
		},
        {
          label1: "Daily Drawdown",
          label2: "4% - $400 (static)",
          label3: "-",
          label4: "4% - $400 (static)",
          content:
			"Stay on track with a 4% daily loss limit to safeguard your trading journey. Your equity or balance must not drop more than 4% below the starting day's equity, ensuring controlled risk management. For example, if you start the day with $10,800, your allowable drawdown threshold for that day is $10,400."
        },
        {
          label1: "Max Drawdown",
          label2: "5% - $500 (trailing)",
          label3: "-",
          label4: "5% - $250 (trailing)",
          content:
			"With our 1-step account, your maximum loss is capped at 5% of your initial balance, adjusting as your account grows. For example, starting with $10,000, if your balance reaches $11,000, your max overall loss is set at $10,500. This dynamic loss limit protects your capital while giving you room to grow as your account balance increases."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "-",
          label4: "1",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "-",
          label4: "$3/lot",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "25",
      content: [
        {
          label1: "Profit Target",
          label2: "10% - $2,500 (static)",
          label3: "-",
          label4: "-",
          content:
			"Achieve a 10% profit target during the challenge phase and unlock a no-profit-target funded phase with our 1-step account. Focus on growth, not restrictions, as you transition to real-market success."
        },
        {
          label1: "Daily Drawdown",
          label2: "4% - $1,000 (static)",
          label3: "-",
          label4: "4% - $1,000 (static)",
          content:
			"Stay on track with a 4% daily loss limit to safeguard your trading journey. Your equity or balance must not drop more than 4% below the starting day's balance, ensuring controlled risk management. For example, if you start the day with $26,500, your allowable drawdown threshold for that day is $25,500."
        },
        {
          label1: "Max Drawdown",
          label2: "5% - $1,250 (trailing)",
          label3: "-",
          label4: "5% - $1,250 (trailing)",
          content:
			"With our 1-step account, your maximum loss is capped at 5% of your initial balance, adjusting as your account grows. For example, starting with $25,000, if your balance reaches $26,500, your max overall loss is set at $25,250. This dynamic loss limit protects your capital while giving you room to grow as your account balance increases."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "-",
          label4: "1",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
			"Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "-",
          label4: "$3/lot",
          content:
			"Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
			"Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "50",
      content: [
        {
          label1: "Profit Target",
          label2: "10% - $5,000 (static)",
          label3: "-",
          label4: "-",
          content:
			"Achieve a 10% profit target during the challenge phase and unlock a no-profit-target funded phase with our 1-step account. Focus on growth, not restrictions, as you transition to real-market success."
        },
        {
          label1: "Daily Drawdown",
          label2: "4% - $2,000 (static)",
          label3: "-",
          label4: "4% - $2,000 (static)",
          content:
			"Stay on track with a 4% daily loss limit to safeguard your trading journey. Your equity or balance must not drop more than 4% below the starting day's equity, ensuring controlled risk management. For example, if you start the day with $52,500, your allowable drawdown threshold for that day is $50,500."
        },
        {
          label1: "Max Drawdown",
          label2: "5% - $2,500 ((trailing)",
          label3: "-",
          label4: "5% - $2,500 ((trailing)",
          content:
			"With our 1-step account, your maximum loss is capped at 5% of your initial balance, adjusting as your account grows. For example, starting with $5,000, if your balance reaches $53,000, your max overall loss is set at $50,500. This dynamic loss limit protects your capital while giving you room to grow as your account balance increases."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "-",
          label4: "1",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "-",
          label4: "$3/lot",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
			"Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "100",
      content: [
        {
          label1: "Profit Target",
          label2: "10% - $1,0000 (static)",
          label3: "-",
          label4: "-",
          content:
			"Achieve a 10% profit target during the challenge phase and unlock a no-profit-target funded phase with our 1-step account. Focus on growth, not restrictions, as you transition to real-market success."
        },
        {
          label1: "Daily Drawdown",
          label2: "4% - $4,000 (static)",
          label3: "-",
          label4: "4% - $4,000 (static)",
          content:
			"Stay on track with a 4% daily loss limit to safeguard your trading journey. Your equity or balance must not drop more than 4% below the starting day's equity, ensuring controlled risk management. For example, if you start the day with $104,500, your allowable drawdown threshold for that day is $100,500."
        },
        {
          label1: "Max Drawdown",
          label2: "5% - $5,000 (trailing)",
          label3: "-",
          label4: "5% - $5,000 (trailing)",
          content:
			"With our 1-step account, your maximum loss is capped at 5% of your initial balance, adjusting as your account grows. For example, starting with $100,000, if your balance reaches $105,500, your max overall loss is set at $100,500. This dynamic loss limit protects your capital while giving you room to grow as your account balance increases."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
			"Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "-",
          label4: "1",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "-",
          label4: "$3/lot",
          content:
			"Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
			"Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "200",
      content: [
        {
          label1: "Profit Target",
          label2: "10% - $2,0000 (static)",
          label3: "-",
          label4: "-",
          content:
			"Achieve a 10% profit target during the challenge phase and unlock a no-profit-target funded phase with our 1-step account. Focus on growth, not restrictions, as you transition to real-market success."
        },
        {
          label1: "Daily Drawdown",
          label2: "4% - $8,000 (static)",
          label3: "-",
          label4: "4% - $8,000 (static)",
          content:
			"Stay on track with a 4% daily loss limit to safeguard your trading journey. Your equity or balance must not drop more than 4% below the starting day's equity, ensuring controlled risk management. For example, if you start the day with $210,000, your allowable drawdown threshold for that day is $."
        },
        {
          label1: "Max Drawdown",
          label2: "5%-$10,000 (trailing)",
          label3: "-",
          label4: "5%-$10,000 (trailing)",
          content:
			"With our 1-step account, your maximum loss is capped at 5% of your initial balance, adjusting as your account grows. For example, starting with $200,000, if your balance reaches $210,000, your max overall loss is set at $200,000. This dynamic loss limit protects your capital while giving you room to grow as your account balance increases."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "1",
          label3: "-",
          label4: "1",
          content:
            "Get funded faster with just 1 minimum trading day required for our $5,000 - $100,000 accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "-",
          label4: "$3/lot",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "350",
      content: [
        {
          label1: "Profit Target",
          label2: "10%-$35,000 (static)",
          label3: "-",
          label4: "-",
          content:
			"Achieve a 10% profit target during the challenge phase and unlock a no-profit-target funded phase with our 1-step account. Focus on growth, not restrictions, as you transition to real-market success."
        },
        {
          label1: "Daily Drawdown",
          label2: "5%-$17,500 (static)",
          label3: "-",
          label4: "5%-$250 (static)",
          content:
			"Stay on track with a 4% daily loss limit to safeguard your trading journey. Your equity or balance must not drop more than 4% below the starting day's equity, ensuring controlled risk management. For example, if you start the day with $5,400, your allowable drawdown threshold for that day is $5,150."
        },
        {
          label1: "Max Drawdown",
          label2: "10%-$35,000 (trailing)",
          label3: "-",
          label4: "10%-$250 (static)",
          content:
			"With our 1-step account, your maximum loss is capped at 5% of your initial balance, adjusting as your account grows. For example, starting with $5,000, if your balance reaches $5,500, your max overall loss is set at $5,250. This dynamic loss limit protects your capital while giving you room to grow as your account balance increases."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "25",
          label3: "-",
          label4: "25",
          content:
			"Get funded faster with just 1 minimum trading day required for our 5k accounts. Achieve your goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "no",
          label3: "-",
          label4: "$3/lot",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "100%",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    },
    {
      laebl: "500",
      content: [
        {
          label1: "Profit Target",
          label2: "10% - $50,000 (static)",
          label3: "-",
          label4: "-",
          content:
			"Achieve a 10% profit target during the challenge phase and unlock a no-profit-target funded phase with our 1-step account. Focus on growth, not restrictions, as you transition to real-market success."
        },
        {
          label1: "Daily Drawdown",
          label2: "4% - $20,000 (static)",
          label3: "-",
          label4: "4% - $20,000 (static)",
          content:
			"Stay on track with a 4% daily loss limit to safeguard your trading journey. Your equity or balance must not drop more than 4% below the starting day's equity, ensuring controlled risk management. For example, if you start the day with $520,500, your allowable drawdown threshold for that day is $500,500."
        },
        {
          label1: "Max Drawdown",
          label2: "5% - $25,000 (trailing)",
          label3: "-",
          label4: "5% - $1,250 (trailing)",
          content:
			"With our 1-step account, your maximum loss is capped at 5% of your initial balance, adjusting as your account grows. For example, starting with $500,000, if your balance reaches $525,500, your max overall loss is set at $500,500. This dynamic loss limit protects your capital while giving you room to grow as your account balance increases."
        },
        {
          label1: "Trading Period",
          label2: "Unlimited",
          label3: "-",
          label4: "Unlimited",
          content:
            "Experience trading freedom with no time limits in our challenge. Focus on perfecting your strategy at your own pace, without the pressure of a ticking clock."
        },
        {
          label1: "Minimum Tracking Days",
          label2: "40",
          label3: "-",
          label4: "1",
          content:
			"Accelerate your path to funding with just 40 minimum trading days required for our $500,000 accounts. Reach your trading goals efficiently while enjoying the flexibility to trade at your own pace."
        },
        {
          label1: "Max loss per trade",
          label2: "2%",
          label3: "-",
          label4: "2%",
          content:
            "Keep your risk/loss per trade idea at a maximum of 2% of the initial account balance, even when using split positions. A trade idea is defined by positions opened or closed within 2 minutes of each other on the same instrument and direction or those floating at the same time on the same instrument and direction. This ensures disciplined risk management across all your trades."
        },
        {
          label1: "Commissions",
          label2: "0",
          label3: "-",
          label4: "$3/lot",
          content:
            "Enjoy zero commissions exclusively on our challenge accounts, giving you more freedom to focus on your trading performance. Maximize your potential without the extra cost during the challenge phase."
        },
        {
          label1: "Trading Leverage",
          label2: "1:100",
          label3: "-",
          label4: "1:100",
          content:
            "Trade with confidence using our flexible leverage options: 1:100 for FX, 1:50 for indices and gold, and 1:5 for crypto. Tailor your strategy with the leverage that best suits your trading style and asset class."
        },
        {
          label1: "Profit Split Up to",
          label2: "-",
          label3: "-",
          label4: "100%",
          content:
            "Our default profit split offers 80% to the trader and 20% to us. For those aiming for more, add-ons are available at checkout for a 90% or even 100% profit split. Maximize your earnings with flexible profit sharing options tailored to your needs."
        },
        {
          label1: "Weekend, Overnight, News, Swing, EAs",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Trade with flexibility, hold positions overnight and over the weekends to capture longer-term moves and adapt to market conditions. News trading is allowed, but please note that off-the-shelf EAs are not permitted unless it is for risk management purposes. This ensures a tailored trading experience while maintaining control over your strategies."
        },
        {
          label1: "Fee Refund",
          label2: "✓",
          label3: "-",
          label4: "✓",
          content:
            "Your challenge fee is refunded upon your first payout in the funded phase. This way, you start your funded trading journey with a clear financial advantage and no initial out-of-pocket cost."
        },
        {
          label1: "Reset Discount",
          label2: "20%",
          label3: "-",
          label4: "20%",
          content:
            "In the event of an account breach, you'll receive a 20% discount code via email for your next purchase. We value your security and want to ensure you can continue trading with confidence and savings."
        }
      ]
    }
  ]
]);

// djadjkahdkaskh
const transSpan = e => {
  const { label2, label3, label4 } = e;

  // 去掉 "(static)" 部分
  const cleanLabel2 = label2.replace(/\(static\)/g, "").trim();
  const cleanLabel3 = label3.replace(/\(static\)/g, "").trim();
  const cleanLabel4 = label4.replace(/\(static\)/g, "").trim();
  return cleanLabel2;

  // // 检查任意两个值是否相等
  // if (cleanLabel2 === cleanLabel3 && cleanLabel2 !== cleanLabel4) {
  //   return `${cleanLabel2} ${cleanLabel4}`;
  // } else if (cleanLabel2 === cleanLabel4 && cleanLabel2 !== cleanLabel3) {
  //   return `${cleanLabel2} ${cleanLabel3}`;
  // } else if (cleanLabel3 === cleanLabel4 && cleanLabel3 !== cleanLabel2) {
  //   return `${cleanLabel3} ${cleanLabel2}`;
  // } else if (cleanLabel2 === cleanLabel3 && cleanLabel3 === cleanLabel4) {
  //   return cleanLabel2;
  // } else {
  //   return `${cleanLabel2} ${cleanLabel3} ${cleanLabel4}`;
  // }
};
</script>
<style lang="scss" scoped>
.evaluations {
  @include padding-index;

  .evaluations-title {
    font-size: 4.8rem;
    margin-bottom:0.3em;
  }
}

.evaluation-container {
  margin-top: 2rem;
}

.program-tabs {
  display: flex;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  color: var(--nav-color);

  .program-tab-left,
  .program-tab-right {
    flex-basis: 50%;
  }

  .tab-step {
    text-align: right;
    &.active {
    color: #ff592d;
    border-color: #ff592d;
  }
  }
}

.tab {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-top: 0.125rem solid #333;
  border-right: 0.125rem solid #333;
  width: calc(100% - 2rem);
  display: inline-block;
  margin-top: 0.5rem;

  &.active {
    color: #ff592d;
    border-color: #ff592d;
  }
}

.funding-amounts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6rem;
  border: 0.0625rem solid var(--nav-color);
  border-radius: 1.5625rem;
  font-size: 0.8rem;

  span {
    padding: 0.5rem 2.5rem;
    cursor: pointer;
    color: var(--nav-color);

    &.active {
      background-color: #ff592d;
      color: black;
      border: none;
      border-radius: 1.5625rem;
    }
  }
}

.tab-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding:0.4rem 0;

  .a {
    flex-basis: 40%;
    text-align: left;
    color: #d7d7d7;
    font-size:0.8rem;
  }

  .b {
    flex-basis: 60%;
    display: flex;

    span {
      flex-basis: 33%;
      text-align: left;
      color: #8c8c8c;
      font-size:0.8rem;
    }
  }
}
::v-deep .el-collapse {
  border-color:#515151;
}
::v-deep .el-collapse-item__header {
  background: transparent;
  color: var(--nav-color);
  font-size: 1rem;
  height:auto;
  border-color:#515151;
}

::v-deep .el-collapse-item__wrap {
  background: transparent;
  color: var(--nav-color);
}

::v-deep .el-collapse-item__content {
  color: inherit;
}

.tabimg {
  width: 0.6rem;
}
/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .evaluations {
    padding: 0 1rem;

    .evaluations-title {
      font-size: 2rem;
    }
  }
  .checkPhone {
    display: none;
  }
  .program-tabs {
    font-size: 0.8rem;
    .program-tab-left,
    .program-tab-right {
      flex-basis: 100%;
      .tab {
        border-right: none;
        border-top: none;
        border-bottom: 0.0625rem solid #333;
        padding-top: 0;
      }
    }

    .tab-step {
      text-align: left;
      padding-left: 1rem;
      font-size: 0.875rem;
    }
  }

  .funding-amounts {
    overflow: auto;

    span {
      padding: 0.5rem 1.5rem;
      font-size: 0.875rem;
    }
  }

  .tab-content {
    .a {
      flex-basis: 100%;
      margin-bottom: 0.5rem;
    }

    .b {
      span {
        flex-basis: 100%;
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>

<template>
	<!-- 头部导航 -->
	<div class="navparent" @mouseenter="flag = true" @mouseleave="flag = false" :style="{background}">
		<template v-if="!isMobile">
			<div class="nav">
				<!-- 左侧导航 -->
				<ul v-for="(item, index) in nav.slice(0, 4)" :key="index">
					<li style="display: flex;height: var(--nav-show-height);align-items:center">
						{{ item.subNav }}
					</li>
					<TransitionGroup name="nav-item">
						<li v-if="flag" v-for="(child, childIndex) in item.childrenNav" :key="childIndex"
							@click="$go(child.href)">
							{{ child.label }}
						</li>
					</TransitionGroup>
				</ul>
				<div style="width: 12rem;height:1.5rem;display: flex;justify-content: center;">
					<a class="ani" :style="showImg?'width:1.6rem':''">
						<img class="logocenter" :src="$getImg('logo-center.png')" alt="" @click="$go('/')" style="cursor:pointer"/>
					</a>
				</div>
				
				<!-- 右侧导航 -->
				<ul v-for="(item, index) in nav.slice(4)" :key="index">
					<li style="display: flex;height: var(--nav-show-height);align-items:center" @click="$openLink()">
						{{ item.subNav }}
					</li>
					<TransitionGroup name="nav-item">
						<li v-if="flag" v-for="(child, childIndex) in item.childrenNav" :key="childIndex"
							@click="$go(child.href)">
							{{ child.label }}
						</li>
					</TransitionGroup>
				</ul>
				<div style="height:1.5rem;display:flex;justify-content:center;align-items:center">
					<div :style="`padding: 0.25rem 0.5rem; border: 0.0625rem solid ${btn}; height: fit-content; border-radius: 1.5625rem;color: ${btn};font-size:0.5rem;cursor:pointer`"
						@click="$openLink()">
						Buy Challenge
					</div>
				</div>
			</div>
			<transition-group name="nav-item">
				<div style="text-align: right; margin-right: 1rem" v-if="flag">
					<div class="join">Join Our Community</div>
					<div>
						<subImgList></subImgList>
					</div>
				</div>
			</transition-group>
		</template>
		<template v-else>
			<div style="display: flex;align-items: center;padding:0 1.125rem;justify-content: space-between;">
					<img :src="$getImg('logo-left.webp')" alt="" class="logocenter" style="width: 8rem;height:auto;cursor:pointer" @click="$go('/')">
				<div style="display:flex;align-items:center">
					<div v-if="!mobileFlag && showBtn" @click="$openLink()"
						style="color: var(--black);background: var(--red-2);padding:0.25rem 0.5rem;border-radius:3rem;font-weight:800;margin-right:1rem;">
						BUY</div>
					<img :src="$getImg('icon-menu.webp')" v-if="!mobileFlag" alt=""
						style="width: 1.5rem;height: 1.5rem;" @click="mobileFlag = !mobileFlag">
					<img :src="$getImg('icon-menu-opening.gif')" v-else alt="" style="width: 1.5rem;height: 1.5rem;"
						@click="mobileFlag = !mobileFlag">
				</div>
			</div>
			<div v-if="mobileFlag" :style="`padding: 2.8125rem 1.125rem;height: calc( ${diviceHeight} - 11.125rem);`">
				<div style="height: 80%;overflow: auto;">
					<ul v-for="(item, index) in nav" :key="index" style="font-size: 1rem;margin-bottom:0.5rem;">
						<li @click="item.flag = !item.flag" style="color:var(--light-grey)">
							{{ item.subNav }}
						</li>
						<TransitionGroup name="nav-item">
							<li v-if="item.flag" style="text-indent: 1rem;"
								v-for="(child, childIndex) in item.childrenNav" :key="childIndex"
								@click="$go(child.href)">
								{{ child.label }}
							</li>
						</TransitionGroup>
					</ul>
					<div class="join" style="margin-top: 3rem;font-size: 0.8rem;">{{ $trans('Join Our Community') }}
					</div>
					<div style="display: flex;justify-content: space-between;">
						<subImgList></subImgList>
					</div>
				</div>
				<div style="padding: 0 1.125rem;position: relative;height: 25%;">
					<subBtn marginTop="0"></subBtn>
					<video :src="$getImg('fire-2.mp4')" autoplay="autoplay" loop="loop" muted="muted" preload="auto"
						playsinline="playsinline"
						style="width: 100vw;position: absolute;bottom: 0;left: -1.125rem;z-index: -1;"></video>
				</div>
			</div>
		</template>
	</div>
	<div id="placeholder" style="height:4rem"></div>
</template>

<script setup>
	import {
		onMounted,
		reactive,
		ref,
		watch
	} from "vue";
	import {
		useStore
	} from "@/store";
	import {
		TransitionGroup
	} from "vue";
	import {
		throttle
	} from "lodash";
	const showBtn = ref(false)
	const showImg = ref(false)
	const nav = reactive([{
			subNav: "Main",
			childrenNav: [{
					label: "Pricing",
					href: "#pricing"
				},
				{
					label: "Benefits",
					href: "#benefits"
				},
				{
					label: "Payouts",
					href: "#benefits"
				},
				{
					label: "Spread",
					href: "#spread"
				},
				{
					label: "Scaling Plans",
					href: "#scalingPlans"
				},
				{
					label: "Funding Process",
					href: "#scalingPlans"
				}
			],
			flag: false
		},
		{
			subNav: "Plans",
			childrenNav: [{
					label: "Rapid Program",
					href: "#pricing"
				},
				{
					label: "Classic Funding",
					href: "#pricing"
				}
			],
			flag: false
		},
		{
			subNav: "About Us",
			childrenNav: [{
					label: "About Us",
					href: "/about"
				},
				{
					label: "Testimonials",
					href: "#pricing"
				},
				{
					label: "Affiliate Program",
					href: "/propTrading"
				}
			],
			flag: false
		},
		{
			subNav: "Prop Trading",
			childrenNav: [{
					label: "Prop Trading",
					href: "/propTrade"
				},
				{
					label: "Trader Funding",
					href: "/traderFunding"
				},
				{
					label: "Prop Firm",
					href: "/propTradingFirm"
				}
			],
			flag: false
		},
		{
			// subNav: "Blog",
			subNav: "",
			childrenNav: [],
			// childrenNav: [
			//   { label: "Latest Posts", href: "" },
			//   { label: "Prop Funding", href: "" },
			//   { label: "Prop Firm", href: "" },
			//   { label: "Industry News", href: "" },
			//   { label: "Featured Posts", href: "" }
			// ],
			flag: false
		},
		{
			subNav: "Dashboard",
			flag: false
		},
		{
			// subNav: "Support",
			subNav: "",
			childrenNav: [],
			// childrenNav: [
			//   { label: "FAQ", href: "" },
			//   { label: "Contact Us", href: "" }
			// ],
			flag: false
		},
	]);

	const flag = ref(false);
	const mobileFlag = ref(false);
	const isMobile = useStore().isMobile;
	const background = ref('');
	const btn = ref('#8c8c8c');
	const go = function(e) {
		window.open(e);
	};
	const diviceHeight = window.innerHeight + "px";
	    // 监听 count 的变化
	    watch(flag, (newValue, oldValue) => {
	      if(newValue || background.value == 'black')
		  background.value = "black"
		  else
		  background.value = ''
	    });
	onMounted(() => {
		// let query = document.getElementById("placeholder");
		// if (isMobile) {
		//   query.style.height = "110px";
		// } else {
		//   query.style.height = "80px";
		// }
		document.addEventListener('scroll', throttle(() => {
			const scrollTop = window.scrollY; // 当前滚动条距离顶部
			const scrollHeight = document.documentElement.scrollHeight; // 文档总高度
			const clientHeight = window.innerHeight; // 可见窗口高度
			// 计算滚动到的百分比
			const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

			if (scrollPercentage >= 2) {
				if (isMobile)
					showBtn.value = true
				background.value = 'black'
				showImg.value = true
				btn.value = "#bf350a"
			} else {
				if (isMobile)
					showBtn.value = false
				background.value = ''
				showImg.value = false
				btn.value = "#8c8c8c"
			}
		}, 500))
	});
</script>

<style lang="scss" scoped>
	.navparent {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		color: var(--nav-color);
		font-size: 0.6rem;
		padding: 1rem 0;
		z-index: 100;
		--nav-show-height: 1.5rem;
	}

	.nav {
		display: flex;
		justify-content: space-around;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		flex-basis: 7%;
	}

	li {
		margin-bottom: 0.3125rem;
		cursor: pointer;
		overflow: hidden;
		transition: all 0.3s ease-in-out;
	}

	li:hover {
		color: white;
	}

	.join {
		font-size: 1rem;
		font-weight: 800;
		padding-right: 2rem;
		padding-bottom: 3rem;
	}

.ani {
  overflow: hidden;
  height: 100%;
  width: 100%;
  transition: width 2s ease-in-out;
  display: flex;
  justify-content: center;
  border-radius: 30%;
  padding-left: 0.3rem;
}
	.logocenter {
		height: var(--nav-show-height);
		width: auto;
	}

	.nav-item-enter-active,
	.nav-item-leave-active {
		transition: all 0.3s ease;
	}

	.nav-item-enter-from,
	.nav-item-leave-to {
		opacity: 0;
		transform: translateY(-0.625rem);
	}

	.nav-item-enter-to,
	.nav-item-leave-from {
		opacity: 1;
		transform: translateY(0);
	}
</style>
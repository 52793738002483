<template>
  <div class="why-choose-container">
    <h1 class="title" v-if="!isMobile">WHY CHOOSE FUNDING <br>TRADERS FOR <br>PROPRIETARY TRADING</h1>
    <h1 class="title" v-else>WHY CHOOSE FUNDING <br>TRADERS FOR <br>PROPRIETARY<br> TRADING</h1>
    <ul class="features-list">
      <li class="feature-item">
        <h3>BIGGER ACCOUNT SIZES</h3>
        <p>
          Explore a prop funding opportunity that allows some of the biggest account sizes in the industry—starting from $10K all the way up to $4M.
          With multiple options to choose from, you can customize accounts to best fit your trading style, preferences, and strategies. Once funded, you
          start trading on these accounts with higher, profitable, and more advanced options.
        </p>
      </li>
      <li class="feature-item">
        <h3>RISK SHARING</h3>
        <p>
          As your trusted proprietary trading partner, we allow you to assume the risk associated with managing funded capital. With flexible trading sizes,
          we extend to you access to diverse programs designed to minimize both losses and maximize profitability. Create a risk-controlled environment
          that lets you amplify your funded accounts and take limited risks based on returns for your trading journey, without any psychological pressures.
        </p>
        <a href="#" class="link" @click="$openLink()">Gain up-to $2,000,000 in capital - Get Funded Now.</a>
      </li>
      <li class="feature-item">
        <h3>FLEXIBLE TRADING CONDITIONS</h3>
        <p>
          We provide traders with complete flexibility, supported by an environment tailored to all clients. As market leaders, we encourage various trading styles,
          strategies, and operations—providing the stability, tools, and trust you need to achieve success. You are allowed to hold positions overnight/weekend, utilize
          Expert Advisors (EAs), and mitigate risks using various trade models. Carefully analyze all trading tactics to start your journey with funding traders.
        </p>
      </li>
      <li class="feature-item">
        <h3>COMPETITIVE PROFIT SPLIT</h3>
        <p>
          Currently, we maintain the industry's best and most competitive profit-sharing trading environment. When you choose proprietary trading, you are entitled
          to default payouts, high earning profits, and split terms built around your success. We reward profitable trading opportunities that are rewarded with up
          to 100% profit split.
        </p>
      </li>
    </ul>
    <div class="image-section">
      <img :src="$getImg('phone.webp')" alt="Trading dashboard on phone" />
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store";
const isMobile = useStore().isMobile;
/* No dynamic logic required for this layout */
</script>

<style scoped lang="scss">
.why-choose-container {
  color: #d7d7d7;
  text-align: left;
  @include padding-components;

  .title {
    font-size: 4.8rem;
    line-height:0.8;
    margin-bottom: 2rem;
    color: white;
  }

  .features-list {
    margin: 0 auto;
    margin-left:20%;
    width:70%;
    padding: 0;

    .feature-item {
      margin-bottom: 1.25rem;

      h3 {
        font-size: 1rem;
        @include body-set(500);
        margin-bottom: 0.625rem;
        color: white;
      }

      p {
        font-size: 0.8rem;
        line-height: 0.9;
        margin-bottom: 0.625rem;
        color: #8c8c8c;
      }

      .link {
        color: #bf350a;
        font-size: 0.8rem;
        font-weight: bold;

        &:hover {
          color: #ff7700;
        }
      }
    }
  }

  .image-section {
    display: flex;
    margin-top: 1.875rem;
    margin-left:20%;
	position:relative;

    img {
      max-width: 60%;
      height: auto;
      border-radius: 0.625rem;
      box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.8);
	  position:relative;
	  z-index:1;
    }
  }
  .image-section::before{
				position: absolute;
				    top: -60%;
				    left: -10%;
				    right: 0;
				    height: 180%;
				    background-image: url('@/assets/background-glow.webp');
				    background-size: 100% 100%;
				    background-repeat: no-repeat;
				    content: " ";
			}

  /* Responsive Design */
  @media (max-width: 768px) {
    @include mobile-padding-components;
	.image-section::before{
		height:200% !important;
	}
    .title {
      font-size: 2.2rem !important;
      text-align:left;
    }

    .features-list {
      padding: 0 0.625rem;
      width:calc(100% - 1.875rem);
      margin-left:0.625rem;

      .feature-item {
        h3 {
          font-size: 1.1rem;
        }

        p {
          font-size: 0.9rem;
        }

        .link {
          font-size: 0.9rem;
        }
      }
    }
    .image-section{
      margin-left:0 !important;
      margin-top:5rem;
    }
    .image-section img {
      max-width:100%;
      width:100%;
    }
  }
}
</style>

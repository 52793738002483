<template>
    <div class="firmTrade">
        <customerNav></customerNav>
        <Best
        title="Best Prop Firm to Trade with $1M+ Capital"
        description1="Join a community of professional prop traders and get paid with 100% profit splits every week. Complete our prop firm challenges to secure big forex funded accounts up to $1 million+. With a straightforward evaluation process, Funding Traders empowers professional clients to earn bigger, and get paid faster."
        description2=""
        img="front.webp"
        :show="false"
        ></Best>
        <Company></Company>
        <Best
        title="Trading Tools and Technology"
        description1="FundingTraders equips traders with state-of-the-art trading technology and tools only available at our proprietary trading firm."
        description2=""
        description3="This empowers them with real-time market data, predictive analytics, risk management solutions, and other vital trading instruments, ensuring they stay ahead in the fast-paced, ever-changing forex market."
        img="dashboard.webp"
        :show="false"
        ></Best>
        <traderc
        title="Forex Education"
        description1="Capital is not the only resource we offer. As a leading proprietary trading firm, we offer comprehensive educational resources and training programs to our traders. "
        description2="From novice to experienced, our educational resources aim to enhance their market understanding, trading skills, and risk management strategies. No other prop firm offers a vast educational program like ours."
        img="eval.webp"
        ></traderc>
        <firm></firm>
        <Mission></Mission>
        <customerBottom></customerBottom>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.firmTrade{
	overflow-x: hidden;
	background: url('@/assets/banner-prop-trading-firm.webp');
	background-repeat: no-repeat;
	background-size: auto auto;
	background-position: top right;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import { createPinia } from 'pinia';
import registerGlobalComponents from './plugins/index.js';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { getImg, go,openLink,transUpper } from './utlis';
let app = createApp(App);
app.config.globalProperties.$getImg = getImg
app.config.globalProperties.$router = router;
app.config.globalProperties.$go = go
app.config.globalProperties.$openLink = openLink
app.config.globalProperties.$trans = transUpper


app.use(ElementPlus)
const pinia = createPinia();
registerGlobalComponents(app);
app.use(router).use(pinia).mount('#app')
import "@/utlis/globalComponent"

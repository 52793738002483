<template>
    <div style="overflow-x: hidden;">
        <customerNav></customerNav>
        <refund></refund>
        <customerBottom></customerBottom>
    </div>
</template>

<script>
</script>

<style lang="scss" scoped></style>
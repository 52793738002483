<template>
    <div class="reasons-container">
      <h1 class="title">REASONS TO JOIN US</h1>
      <div class="reasons-content">
        <ul class="reasons-list">
          <li v-for="(reason, index) in leftReasons" :key="'left-' + index">
            {{ reason }}
          </li>
        </ul>
        <ul class="reasons-list">
          <li v-for="(reason, index) in rightReasons" :key="'right-' + index">
            {{ reason }}
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script setup>
import { ref } from "vue";

const leftReasons = ref([
  "BEST PRICE IN MARKET",
  "FEE REFUND",
  "RESET DISCOUNT 20%",
  "COMPETITIONS WITH PRIZES",
  "SCALING PLAN UP TO $4M",
  "AI ANALYSIS",
  "24/7 SUPPORT",
  "MENTORSHIP FROM CEO",
  "SPREAD STARTING FROM 0.0 PIPS"
]);

const rightReasons = ref([
  "TRADING APPS",
  "AI ANALYSIS",
  "SCALING PLAN UP TO $4M",
  "NEWS / SWING / EAS TRADING",
  "WEEKEND / OVERNIGHT HOLDING",
  "NO TIME LIMIT",
  "NO COMMISSION P1 P2, F-3$",
  "BALANCE BASED DRAWDOWN",
  "100% PAYOUTS IN 7 DAYS*"
]);
</script>
  
  <style scoped lang="scss">
.reasons-container {
  background: url("@/assets/banner-reasons-to-join.webp");
  background-size: cover;
  color: #fff;
  @include padding-components;
  padding-top: 10rem;
  padding-bottom: 10rem;
  display: flex;
  font-family: Arial, sans-serif;

  .title {
    font-size: 4.8rem;
    margin-bottom: 1.875rem;
    flex-basis: 25%;
    line-height:0.8;
    margin: 0;
  }

  .reasons-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left:5rem;

    .reasons-list {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 45%;
      text-align: left;

      li {
        font-size: 0.8rem;
        margin-bottom: 0.625rem;
      }
    }
  }
}
@media (max-width: 768px){
  .title{
    font-size:2rem !important;
  }
  .reasons-container{
    flex-direction:column;
    padding:2rem 0.5rem;
  }
  .reasons-content{
    flex-direction: column;
    margin-left:0 !important;
  }
  .reasons-list{
    width:100% !important;
    margin-top: 2rem !important;
    &:nth-of-type(2){
      margin:0 !important;
    }
  }
}
</style>
  
<template>
  <div>
    <div class="title">
      <h1>TRADE UP TO $2M GET PAID 100% WEEKLY</h1>
      <span class="titleSpan">100% PROFIT SPLIT & 7 DAY PAYOUTS</span>
      <span class="titleSpan">NO TIME LIMITS, NO COMMISSIONS, NO SWAPS</span>
      <span class="titleSpan">MAJOR TRADING PLATFORMS AVAILABLE</span>
      <template v-if="isMobile">
        <div class="centered">
          <subBtn :flag="false" text="GET FUNDED"></subBtn>
        </div>
<!--        <video class="background-video" style="bottom:60%;" :src="$getImg('fire-2.mp4')" autoplay="autoplay" loop="loop"
          muted="muted" preload="auto" playsinline="playsinline"></video> -->
        <div class="swiper">
          <div v-for="(item, index) in ImgList.concat(ImgList)"
            :key="`${item.id || index}-${index < ImgList.length ? 'original' : 'clone'}`" class="swiper-item">
            <img :src="item.src" alt="">
          </div>
        </div>
        <div class="media-container">
          <video :src="$getImg('intro.mp4')" controls :poster="$getImg('intro.webp')" playsinline preload
            style="margin-top:2rem"></video>
          <div class="image-container">
            <img :src="$getImg('prop-firm-match-reviews.webp')" alt="" style="margin-bottom:1rem"  @click="$openLink()">
            Join Our Community
            <div class="sub-img-list">
              <subImgList></subImgList>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="media-container">
          <div class="image-container">
            <img :src="$getImg('prop-firm-match-reviews.webp')" alt=""  @click="$openLink()">
            <div class="sub-img-list">
              <subImgList></subImgList>
            </div>
          </div>
          <div class="centered">
            <subBtn :flag="false" text="GET FUNDED" marginTop="0px"></subBtn>
          </div>
          <video :src="$getImg('intro.mp4')" controls :poster="$getImg('intro.webp')" playsinline preload></video>
        </div>
        <video class="background-video" :src="$getImg('fire-2.mp4')" autoplay="autoplay" loop="loop" muted="muted"
          preload="auto" playsinline="playsinline"></video>
        <div class="swiper">
          <div v-for="(item, index) in ImgList.concat(ImgList)"
            :key="`${item.id || index}-${index < ImgList.length ? 'original' : 'clone'}`" class="swiper-item"
            :style="{ width: `calc(100% / ${ImgList.length})` }">
            <img :src="item.src" alt="">
          </div>
        </div>
      </template>

      <div class="info">
        <div v-for="(item, index) in info" :key="index" class="item">
          <span class="label">{{ item.label }}</span>
          <span class="value">{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { reactive } from "vue";
  import { useStore } from "@/store";
  const isMobile = useStore().isMobile;

  const ImgList = reactive([
    {
      src: require("@/assets/index-swiper/1.webp"),
      link: ""
    },
    {
      src: require("@/assets/index-swiper/2.webp"),
      link: ""
    },
    {
      src: require("@/assets/index-swiper/3.webp"),
      link: ""
    },
    {
      src: require("@/assets/index-swiper/4.webp"),
      link: ""
    }
  ]);
  const info = reactive([
    {
      label: "Total Payouts in 2023",
      value: "$4.7M+"
    },
    {
      label: "Funded Traders",
      value: "5.6K+"
    },
    {
      label: "Avg. Payout Time",
      value: "24 Hours"
    }
  ]);
</script>

<style lang="scss" scoped>
  .title {
    position: relative;

    h1 {
      font-size: 8rem;
	  line-height:0.8;
      font-weight: 800;
	  letter-spacing: 0.03rem;
      text-align: center;
      @include padding-index;
      margin: 1rem 0 3rem;
      // margin-bottom: 12.8rem;
    }

    .titleSpan {
      color: #d7d7d7;
      display: block;
	  font-weight: 500;
      margin-left: 25%;
	  font-size: 0.8rem;
    }

    .titleSpan:nth-of-type(2) {
      text-indent: 8rem;
    }

    .titleSpan:nth-of-type(3) {
      text-indent: 16rem;
    }
  }

  .centered {
    width: 10rem;
    align-self: flex-start;
  }

  .media-container {
	background:linear-gradient(0deg, var(--black) 5%, var(--black-transparent) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include padding-index;
    margin-top:2rem;
    .image-container {
      width: 15rem;

      img {
        width: 100%;
      }

      .sub-img-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 0.8rem;
      }
    }

    video {
      width: 12rem;
    }
  }

  .background-video {
    position: absolute;
    width: 100vw;
    bottom: 40%;
    left: 0;
    z-index: -1;
  }

  .swiper {
    opacity: 0.6;
    margin-top: 3rem;
    white-space: nowrap;
    animation: scroll 20s linear infinite;
    width: 100vw;

    .swiper-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50%;
      }
    }
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }

  .info {
    margin: 0 2rem;
    margin-top: 4rem;
    border-top: 0.08rem solid #515151;
    display: flex;

    .item {
      display: flex;
      flex-basis: 33%;
      border-right: 0.08rem solid #515151;
      padding-top: 0.8rem;
      height: 4rem;

      .label {
        color: var(--nav-color);
        text-indent: 1.6rem;
        font-size: 0.8rem;
      }

      .value {
        color: #c1c1c1;
        font-weight: 800;
        flex: 1;
        align-self: flex-end;
        text-align: right;
        margin-right: 1.6rem;
        font-size: 1.5rem;
        @include title-set;
      }
    }

    .item:nth-of-type(3) {
      border: none;
    }
  }

  /* 添加媒体查询 */
  @media (max-width: 768px) {
    .title {
      h1 {
        font-size: 3rem;
        margin-bottom: 6.8rem;
      }

      .titleSpan {
        margin-left: 0;
        text-indent: 0;
        text-align: center;
        text-indent: 0 !important;
        margin: 1rem 20%;
        // width: 15rem;
		font-size:0.6rem;
      }
    }

    .centered {
      text-align: center;
      width: 100%;
    }

    .cta-btn {
      margin: 2rem auto;
    }

    .media-container {
      flex-direction: column;
      padding: 0;

      .image-container,
      video {
        width: 100%;
      }
    }

    .swiper {
      margin-top: 4rem;
    }

    .info {
      flex-direction: column;
      margin: 2rem 0;

      .item {
        flex-basis: 100%;
        border-right: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.08rem solid #515151 !important;
        padding-bottom: 1.6rem;

        .label {
          text-indent: 0;
          text-align: left;
        }

        .value {
          // text-align: left;
          margin-right: 0;
        }
      }
    }
  }
</style>
<template>
  <div class="spread-section" id="spread">
    <!-- Header Section -->
    <div class="header">
      <h1>EXPLORE OUR SPREADS, STARTING FROM 0.0 PIPS</h1>
      <div class="label">
        Login to our trading platforms with these credentials to check out our
        raw spreads across different instruments.
        <div class="sub-label">
          Guaranteed best prop trading conditions with competitive spreads and minimal slippage on execution.
        </div>
      </div>
    </div>

    <!-- Content Section -->
    <div class="platforms" v-if="!isMobile">
      <!-- TRADELOCKER -->
      <div class="platform-box">
        <h2>TRADELOCKER</h2>
        <div class="info">
          <div class="row">
            <span>Login ID</span>
            <span>
              → support@fundingtraders.com
              <button @click="copyToClipboard('support@fundingtraders.com')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
          <div class="row">
            <span>Investor Password</span>
            <span>
              → u2XP#sdYT4w
              <button @click="copyToClipboard('u2XP#sdYT4w')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
          <div class="row">
            <span>Server Name</span>
            <span>
              → FUTRAD
              <button @click="copyToClipboard('FUTRAD')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
        </div>
      </div>

      <!-- DXTRADE -->
      <div class="platform-box">
        <h2>DXTRADE</h2>
        <div class="info">
          <div class="row">
            <span>Login ID</span>
            <span>
              → help@fundingtraders.com
              <button @click="copyToClipboard('help@fundingtraders.com')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
          <div class="row">
            <span>Investor Password</span>
            <span>
              → Fitisthebest123
              <button @click="copyToClipboard('Fitisthebest123')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
          <div class="row">
            <span>Server Name</span>
            <span>
              → N/A
              <button @click="copyToClipboard('N/A')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="platforms" v-else>
      <!-- TRADELOCKER -->
      <div class="funding-amounts">
        <span :class="{active:activeTab == 0}" @click="activeTab=0">TRADELOCKER</span>
        <span :class="{active:activeTab == 1}" @click="activeTab=1">DXTRADE</span>
      </div>
      <div class="platform-box" v-if="activeTab == 0">
        <div class="info">
          <div class="row">
            <span>Login ID</span>
            <span>
              → support@fundingtraders.com
              <button @click="copyToClipboard('support@fundingtraders.com')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
          <div class="row">
            <span>Investor Password</span>
            <span>
              → u2XP#sdYT4w
              <button @click="copyToClipboard('u2XP#sdYT4w')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
          <div class="row">
            <span>Server Name</span>
            <span>
              → FUTRAD
              <button @click="copyToClipboard('FUTRAD')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
        </div>
      </div>

      <!-- DXTRADE -->
      <div class="platform-box" v-if="activeTab == 1">
        <div class="info">
          <div class="row">
            <span>Login ID</span>
            <span>
              → help@fundingtraders.com
              <button @click="copyToClipboard('help@fundingtraders.com')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
          <div class="row">
            <span>Investor Password</span>
            <span>
              → Fitisthebest123
              <button @click="copyToClipboard('Fitisthebest123')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
          <div class="row">
            <span>Server Name</span>
            <span>
              → N/A
              <button @click="copyToClipboard('N/A')">
                <img :src="$getImg('copy.webp')" alt="Copy" />
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useStore } from "@/store";
  import { onMounted, reactive, ref } from "vue";
  const isMobile = useStore().isMobile;
  const activeTab = ref(0)
function copyToClipboard(text) {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(text).catch(err => {
      console.error("Failed to copy text: ", err);
    });
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.left = "-9999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Fallback: Failed to copy text: ", err);
    }
    document.body.removeChild(textArea);
  }
}
</script>

<style lang="scss" scoped>
.spread-section {
  background-color: #000;
  color: #fff;
  padding:4rem 0;
  @media (max-width: 768px) {
    padding: 2rem 0.5rem;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  margin:1rem;
}

.header h1 {
  font-size: 4.8rem; /* 48px -> rem */
  flex-basis: 70%;
  margin: 0;
  line-height:0.8;
  @media (max-width: 768px) {
    font-size: 2rem;
    flex-basis: 100%;
  }
}

.header .label {
  font-size: 0.6rem; /* 16px -> rem */
  line-height: 1.5;
  color: #d7d7d7;
  flex: 1;
  @media (max-width: 768px) {
    .sub-label {
      margin: 0;
      display: inline;
      color: inherit;
    }
  }
}

.header .sub-label {
  margin-left: 3rem; /* 64px -> rem */
}

.platforms {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem; /* 32px -> rem */
  margin-top: 2rem; /* 32px -> rem */

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  display: initial;
  .platform-box{
    padding: 1.5rem 0;
  }
  }
}

.platform-box {
  padding: 1.5rem; /* 24px -> rem */
  border-radius: 0.5rem; /* 8px -> rem */
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5); /* 4px, 8px -> rem */
  font-size:0.8rem;
}

.platform-box h2 {
  font-size: 1.5rem; /* 24px -> rem */
  margin-bottom: 1rem; /* 16px -> rem */
  color: #fff;
  @include body-set();
}

.info {
  text-align: left;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0; /* 8px -> rem */
  border-bottom: 0.08rem solid #515151; /* 2px -> rem */
  @media (max-width: 768px) {
    flex-direction: column;
    border-bottom: 0;
    span:nth-of-type(2) {
      border-bottom: 0.08rem solid #515151;
      border-top: 0.08rem solid #515151;
      padding: 0.5rem 0;
      margin-top: 0.5rem;
    }
  }
}

.row span {
  color: #ccc;
}

.row span:nth-of-type(1) {
  flex-basis: 40%;
}

.row span:nth-of-type(2) {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.row button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}

.row button:hover {
  color: #f90;
  text-decoration: underline;
}

.row img {
  width: 0.8rem; /* 16px -> rem */
  height: 0.8rem; /* 16px -> rem */
}
.funding-amounts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6rem;
  border: 0.0625rem solid var(--nav-color);
  border-radius: 1.5625rem;
  font-size: 0.8rem;
  width:100%;
  span {
    padding: 0.5rem 0;
    flex-basis:50%;
    text-align:center;
    cursor: pointer;
    color: var(--nav-color);

    &.active {
      background-color: #ff592d;
      color: black;
      border: none;
      border-radius: 1.5625rem;
    }
  }
}
</style>

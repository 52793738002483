import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/index.vue';
import about from '../views/about/index.vue'
import propTrading from '@/views/about/propTrading.vue';
import propTrade from '@/views/propTrade/index.vue';
import traderFunding from "@/views/trader-funding/index.vue";
import propTradingFirm from "@/views/prop-trading-firm/index.vue";
import terms from '@/views/terms/index.vue';
import privacyPolicy from "@/views/privacy-policy/index.vue"
import refund from "@/views/refund/index.vue"
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home, // 指定组件
  },
  {
    path: '/about',
    name: 'About',
    component: about,
  },
  {
    path: '/propTrading',
    name: 'propTrading',
    component: propTrading,
  },
  {
    path: '/propTrade',
    name: 'propTrade',
    component: propTrade,
  },
  {
    path: '/traderFunding',
    name: 'traderFunding',
    component: traderFunding,
  },
  {
    path: '/terms',
    name: 'terms',
    component: terms,
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: privacyPolicy,
  },
  {
    path: '/refund',
    name: 'refund',
    component: refund,
  },
  {
    path: '/propTradingFirm',
    name: 'propTradingFirm',
    component: propTradingFirm,
  },
];

const router = createRouter({
  history: createWebHashHistory(), // 使用 HTML5 的 history 模式
  routes,
});

export default router;

<template>
  <div class="rewards-container">
    <h1 class="title">REWARDS FOR<br> SYSTEM RESULT</h1>
    <div class="reward-card" v-for="(reward, index) in rewards" :key="index">
      <div class="reward-info">
        <span class="reward-amount">
          <img :src="$getImg('award.webp')" alt="Reward Icon" />
        </span>
        <div class="flex-box">
          <span>{{ reward.amount }}</span>
          <span class="reward-details">{{ reward.details }}</span>
          <div class="reward-program">{{ reward.program }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        rewards: [
          {
            amount: "$25K",
            details: "25 REFERRALS/MONTH",
            program: "Rapid Program - 1 Step Account"
          },
          {
            amount: "$50K",
            details: "50 REFERRALS/MONTH",
            program: "Rapid Program - 1 Step Account"
          },
          {
            amount: "$100K",
            details: "100 REFERRALS/MONTH",
            program: "Rapid Program - 1 Step Account"
          }
        ]
      };
    }
  };
</script>

<style scoped lang="scss">
  .rewards-container {
    background-color: #000;
    color: #d7d7d7;
    @include padding-components;
    font-family: Arial, sans-serif;

    .title {
      font-size: 4.8rem;
      font-weight: bold;
      margin-bottom: 2rem;
      color: white;
      line-height:0.9;
    }

    .reward-card {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-top: 0.0625rem solid #8c8c8c;
      padding: 0.9375rem 1.25rem;
      transition: all 0.3s ease-in-out;
      margin-left:30%;
      color: var(--dark-grey);

      &:nth-last-of-type(1) {
        border-bottom: 0.0625rem solid #8c8c8c;
      }

      &:hover {
        color: white;
        border-color: white;

        img {
          filter: grayscale(0) !important;
        }
      }
    }

    .reward-info {
      display: flex;
      flex: 1;
      align-items: center;

      .reward-amount {
        font-size: 2.5rem;
        font-weight: bold;

        img {
          width: 2rem;
          margin-right: 0.9375rem;
          filter: grayscale(1);
          transition: all 0.3s ease-in-out;

        }
      }

      .flex-box {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          display: block;
          font-size: 2rem;
          @include title-set;
        }

        .reward-details {
          font-size: 0.8rem;
          @include body-set;
        }

        .reward-program {
          font-size: 0.8rem;
        }
      }
    }

    @media (max-width: 768px) {
        padding: 1rem 0.25rem ;

      .title {
        text-align: left;
        font-size: 2.5rem;
      }

      .reward-card {
        margin-left:0;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        padding: 0.625rem 0;
        border-top:none;
        border-bottom: 0.0625rem solid #8c8c8c;
        &:hover {
          border-color: #8c8c8c;
        }
      }

      .reward-info {

        .reward-amount {
          font-size: 2rem;
          margin-bottom: 0.625rem;

          img {
            width: 3rem;
          }
        }

        .flex-box {
          flex-direction: column;
          align-items: flex-start;
          span {
            font-size: 2rem;
          }

          .reward-details {
            font-size: 0.8rem;
          }

          .reward-program {
            font-size: 0.8rem;
            margin-top: 0.3125rem;
          }
        }
      }
    }
  }

</style>

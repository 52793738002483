<template>
	<div class="pro">
		<customerNav></customerNav>
		<Affiliate></Affiliate>
		<dayCookie></dayCookie>
		<Rewards></Rewards>
		<Partner></Partner>
		<Reasons></Reasons>
		<Traders></Traders>
		<customerBottom></customerBottom>
	</div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
	.pro {
		background: url("@/assets/banner-affiliates.webp");
		background-repeat: no-repeat;
		background-size: auto;
		background-position: top right;
		overflow-x: hidden;
	}
</style>
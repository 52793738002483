<template>
  <div class="cookie-section">
    <div class="cookie-content">
      <h1>60-DAY COOKIE</h1>
      <div class="flex-box">
        <p class="first">
          {{ $trans('Use your unique URL when referring traders. Promote easily with any method(s) using your unique link.') }}
        </p>
        <p class="small-text">
          Use your unique URL on the affiliate dashboard to refer traders. When
          they purchase within 60 days of clicking your link, you earn 10% of
          their purchase amount.
        </p>
      </div>
    </div>
    <!-- Image section for the URL bar -->
    <div class="image-bar">
      <img :src="$getImg('url.webp')" alt="URL Bar Example" class="url-image" />
    </div>
    <p class="warning">
      <img :src="$getImg('exclamation.webp')" class="gth" />
      <span>
        The purchase must be made not in incognito mode, and in the
        same browser.
      </span>
    </p>
  </div>
</template>

<script>
  export default {
    name: "CookieSection",
  };
</script>

<style lang="scss" scoped>
  .cookie-section {
    background: linear-gradient(200deg, var(--soft-grey) 65%, var(--orange) 92%, var(--orange-60) 100%);
    @include padding-components;
    color: black;

    .cookie-content {
      margin-bottom: 1.25rem;

      h1 {
        font-size: 5rem;
        font-weight: bold;
        margin-bottom: 0.625rem;
        margin-top:0;
      }

      .flex-box {
        display: flex;
        gap: 2rem;
        margin-top:2rem;

        .first {
          font-size: 1.5rem;

        }
      }

      p {
        font-size: 1rem;
        max-width: 45%;
        font-weight:500;
        margin:0;
      }

      .small-text {
        font-size: 0.6rem;
        max-width: 20%;
        margin-left:10%;
      }
    }

    .image-bar {
      margin: 1.25rem auto;


      .url-image {
        max-width: 100%;
        height: auto;
        border-radius: 0.625rem;
      }
    }

    .warning {
      font-size: 0.6rem;
      margin-top: 0.625rem;
      display: flex;
      flex-direction: column;
      margin-left:60%;
      width:18%;

      .gth {
        width: 1.5rem;
        height: 1.5rem;
      }

      span {
        font-weight: 500;
        margin-top: 0.5rem;
      }
    }

    @media (max-width: 768px) {
      padding: 2rem 0.5rem;

      * {
        text-align: left !important;
      }

      .cookie-content h1 {
        font-size: 2rem;
      }

      .flex-box {
        gap: 1rem;
        flex-direction: column;
        .first {
          font-size: 1.2rem;
          flex:1;
            max-width:100%;
        }
        .small-text{
          flex:1;
          max-width:100%;
          margin-left:0;
        }
      }

      .image-bar .url-image {
        max-width: 100%;
      }

      .warning {
        font-size: 0.8rem;
        align-items: flex-start;
        width:100%;
        margin-left:0;
        .gth {
          width: 2rem;
          height: 2rem;
        }

        span {
          font-size: 0.9rem;
        }
      }
    }
  }
</style>

<template>
	<div class="customerBottom">
		<div class="header">
			<div class="left">
				<img :src="$getImg('logo-left.webp')" alt="" @click="$go('/')" style="cursor:pointer"/>
				<p class="label">
					{{$trans(`Empowering traders with flexible and reliable funding solutions for
					success`)}}
				</p>
			</div>
			<div class="right">
				<ul v-for="(item, index) in nav" :key="index">
					<li @click="$openLink()">{{ item.subNav }}</li>
					<li v-for="(child, childIndex) in item.childrenNav" :key="childIndex" @click="$go(child.href)">
						{{ child.label }}
					</li>
				</ul>
			</div>
		</div>
		<div class="community">{{ $trans('Join Our Community') }}</div>
		<div class="content">
			<div class="left imglist">
				<subImgList :marginRight="isMobile?'0rem':'1rem'"></subImgList>
			</div>
			<div class="right inputright">
				<div class="inputParent">
					<input v-model="email" placeholder="YOUR EMAIL" placeholderClass="placeHolder" :disabled="complete"/>
					<div class="bg"></div>
				</div>
				<button  @click="submit" :disabled="complete">{{!complete?'Subscribe':'Subscribed'}}</button>
			</div>
		</div>
		<div class="content">
			<div class="left resourcesleft">
				<ul>
					<li class="resources">Resources</li>
					<li v-for="(item, index) in ul" :key="index" class="resource-item" @click="$go(item.href)">
						{{ item.label }}
					</li>
				</ul>
				<p>© 2024 FundingTraders. All rights reserved.</p>
			</div>
			<div class="right">
				<p class="disclaimer">
					The content on this website is intended solely for studying purposes
					related to trading on financial markets. It does not provide any
					specific investment recommendations, business advice, investment
					opportunity analysis, or general recommendations regarding investment
					instrument trading. Trading in financial markets involves high risk,
					and it is advised not to risk more than one can afford to lose. The
					company does not offer any investment services listed in the Capital
					Market Undertakings Act. The information on this site is not intended
					for residents of any country or jurisdiction where such distribution
					or use would violate local laws or regulations. The company is not a
					broker and does not accept deposits. Hypothetical or simulated
					performance results have certain limitations. Unlike an actual
					performance record, simulated results do not represent actual
					trading. Also, because the trades have not actually been executed,
					the results may have under-or-over compensated for the impact, if
					any, of certain market factors, such as lack of liquidity. Simulated
					trading programs, in general, are also subject to the fact that they
					are designed with the benefit of hindsight. No representation is
					being made that any account will or is likely to achieve profit or
					losses similar to those shown. This website is owned and operated by
					MCF GROUP of FundingTraders of DSO-FZCO-24876 IFZA Business Park,
					DDP, 26656 - 001, A1 - 3641379065 Dubai UAE and RSRD Limited of Suite
					C Level 7, World Trust Tower, 50 Stanley Street Hong Kong
				</p>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {getUserInfo} from "@/api/email"
	import {
		useStore
	} from "@/store";
	import {
		reactive,
		ref
	} from "vue";
	const isMobile = useStore().isMobile;
	const complete = ref(false)
	const submit = ()=>{
		getUserInfo({email:email.value}).then(r=>{
			complete.value = true
		})
	}
	const email = ref("");
	const ul = reactive([{
			label: "Terms of Use",
			href: "terms"
		},
		{
			label: "Privacy Policy",
			href: "privacyPolicy"
		},
		{
			label: "Refund Policy",
			href: "refund"
		}
	]);
	const nav = reactive([{
			subNav: "HOME",
			childrenNav: [{
					label: "Pricing",
					href: "#pricing"
				},
				{
					label: "Benefits",
					href: "#benefits"
				},
				{
					label: "Payouts",
					href: "#benefits"
				},
				{
					label: "Spread",
					href: "#spread"
				},
				{
					label: "Scaling Plans",
					href: "#scalingPlans"
				},
				{
					label: "Funding Process",
					href: "#scalingPlans"
				}
			],
		},
		{
			subNav: "PLANS",
			childrenNav: [{
					label: "Rapid Program",
					href: "#pricing"
				},
				{
					label: "Classic Funding",
					href: "#pricing"
				}
			],
		},
		{
			subNav: "ABOUT US",
			childrenNav: [{
					label: "About Us",
					href: "/about"
				},
				{
					label: "Testimonials",
					href: "#pricing"
				},
				{
					label: "Affiliate Program",
					href: "/propTrading"
				}
			],
		},
		{
			subNav: "PROP TRADING",
			childrenNav: [{
					label: "Prop Trading",
					href: "/propTrade"
				},
				{
					label: "Trader Funding",
					href: "/traderFunding"
				},
				{
					label: "Prop Firm",
					href: "/propTradingFirm"
				}
			],
		},
		// {
		//   subNav: "Blog",
		//   childrenNav: [
		//     { label: "Latest Posts", href: "" },
		//     { label: "Prop Funding", href: "" },
		//     { label: "Prop Firm", href: "" },
		//     { label: "Industry News", href: "" },
		//     { label: "Featured Posts", href: "" }
		//   ],
		// },
		// {
		//   subNav: "Support",
		//   childrenNav: [
		//     { label: "FAQ", href: "" },
		//     { label: "Contact Us", href: "" }
		//   ],
		// },
		{
			subNav: "DASHBOARD",
			childrenNav: [
				// {
				// 		label: "Buy Challenge",
				// 		href: ""
				// 	},
				// 	{
				// 		label: "Login",
				// 		href: ""
				// 	}
			],
		},
		{
			subNav: "FOR PARTNERS",
			childrenNav: [{
					label: "Affiliate Program",
					href: data.link
				},
				{
					label: "Affiliate Login",
					href: data.link
				}
			],
		}
	]);
</script>

<style lang="scss" scoped>
	.customerBottom {
		margin-top: 10rem;
		border-top: 0.08rem solid #515151;
		padding: 2.5rem 2rem;

		@media (max-width: 768px) {
			padding: 2.5rem 0;
		}
	}

	.header {
		display: flex;
		// flex-wrap: wrap;

		.left {
			flex-basis: 50%;

			img {
				width: 12.5rem;
			}

			.label {
				width: 12.5rem;
				font-size:0.8rem;
			}
		}

		.right {
			flex: 1;
			display: flex;
			flex-direction: column;
			max-height: 12rem;
			flex-wrap: wrap;
			gap: 0.5rem;
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				font-weight: 400;
				font-size: 0.8rem;

				li {
					margin-bottom: 0.3rem;
					cursor: pointer;

					&:not(:first-child) {
						color: var(--nav-color);
					}
				}
			}
		}
	}

	.community {
		margin-top: 1rem;
		font-size: 0.8rem;
	}

	.imglist {
		display: flex;
		align-items: flex-end;
	}

	.content {
		display: flex;
		// flex-wrap: wrap;
		margin-top: 3.25rem;

		.left {
			flex-basis: 100%;

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				font-weight: 400;
				font-size: 0.8rem;

				li {
					margin-bottom: 0.3rem;
					cursor: pointer;

					&.resources {
						color: var(--nav-color);
					}

					&.resource-item {
						color: #515151;
					}
				}
			}

			p {
				color: var(--nav-color);
				font-size: 0.8rem;
			}
		}

		.right {
			flex-basis: 100%;
			display: flex;

			.inputParent {
				flex: 1;
				height: 3.375rem;

				.bg {
					background-image: linear-gradient(90deg, #ce3216 0%, #ea9a33 100%);
					width: 100%;
					height: 0.125rem;
				}

				input {
					outline: none;
					width: 100%;
					background: transparent;
					border: none;
					height: calc(100% - 0.125rem);
					color:var(--light-grey);
					font-size: 2rem;
					@include title-set
				}
			}

			button {
				background: transparent;
				color: #939393;
				border-radius: 1.5625rem;
				border: 0.0625rem solid #939393;
				cursor: pointer;
				font-weight: 800;
				font-size:0.8rem;
				padding: 0 2.25rem;
				height: 70%;
				margin-left: 1rem;
				align-self: flex-end;
			}
		}
	}

	.disclaimer {
		color: #515151;
		font-size: 0.8rem;
		line-height: 1.5;
	}

	@media (max-width: 768px) {
		.customerBottom {
			margin-top: 0;
		}
		.label{
			width:90% !important;
		}

		.header,
		.content {
			flex-direction: column;
		}

		.header .right {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			gap: 1.5rem;
			max-height: 38rem;
			margin-bottom: 3rem;
			font-size: 0.9375rem;
			line-height: 0.9375rem;
			align-items: baseline;
		}

		.imglist {
			display: flex;
			justify-content: space-evenly;
		}

		.header .left,
		.header .right {
			flex-basis: 100%;
		}

		.content .left,
		.content .right {
			flex-basis: 100%;
		}
		.content{
			.left{
				justify-content: space-between;
			}
		}
		.community {
			margin-top: 1rem;
			font-size: 0.8rem;
		}

		button {
			margin-top: 1rem;
			width: 100%;
		}

		.inputright {
			display: flex;
			flex-direction: column;

			.inputParent {
				flex-basis: 100%;
				width: 100%;
				flex: initial !important;
			}

			button {
				width: 100%;
				margin-left: 0 !important;
				font-size: 1rem;
				padding: 0.5rem 0 !important;
			}
		}

		.resourcesleft {
			margin-bottom: 0 !important;
		}
	}
</style>
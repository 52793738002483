import { defineAsyncComponent } from 'vue';

export default function registerGlobalComponents(app) {
  // 使用 Webpack 的 require.context 动态引入组件
  const requireComponent = require.context('../components', true, /\.vue$/);

  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);

    // 提取文件名作为组件名（去掉前缀和后缀）
    const componentName = fileName
      .split('/')
      .pop()
      .replace(/\.\w+$/, ''); // 去掉文件扩展名

    // 注册为全局组件
    app.component(
      componentName,
      defineAsyncComponent(() => Promise.resolve(componentConfig.default))
    );
  });
}
<template>
  <div style="overflow-x: hidden;">
    <customerNav></customerNav>
    <div class="content">
      <WEEKLY></WEEKLY>
      <Evaluations></Evaluations>
      <Leading></Leading>
      <PayoutSystem></PayoutSystem>
      <PIPS></PIPS>
      <Opportunities></Opportunities>
      <Traders></Traders>
      <customerBottom></customerBottom>
    </div>
  </div>
</template>

<script>
</script>

<style lang="scss" scoped>
</style>
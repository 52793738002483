// src/api/user.js
import request from '@/api/index';

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: '/api/email/subscribe',
    method: 'post',
	data
  });
}

<template>
	<div class="section">
		<h1 class="section-title">{{ $trans("Why We're The") }}
			<br> {{ $trans('Leading Prop Firm') }}
		</h1>
		<div class="features-grid">
			<div v-for="(feature, index) in features" :key="index" :class="`feature-box feature-box-${index + 1}`"
				:style="{background:`url(${require(`@/assets/index-grid/${index+1}.webp`)})`,backgroundPosition:'100% 50%',backgroundSize:'cover',backgroundRepeat:'no-repeat'}">
				<h2>{{ $trans(feature.title) }}</h2>
				<ul>
					<li v-for="(item, i) in feature.items" :key="i">{{ $trans(item) }}</li>
				</ul>
				<a v-if="feature.extra" @click="$openLink()">{{ $trans(feature.extra)}}</a>
			</div>
		</div>
	</div>
</template>


<script setup>
	import {
		ref
	} from 'vue';

	const features = ref([{
			title: 'TRADE FREELY',
			items: [
				'News Trading',
				'Swing Trading',
				'Expert Advisors',
				'Weekend / Overnight Holding',
				'No Time Limit',
				'Mentorship from Ex-Wall Street Trader',
			],
		},
		{
			title: 'NO COMMISSIONS, SWAP-FREE',
			items: [
				'Balance / Equity-Based Drawdown',
				'No Commissions and Swap-Free',
				'MT5 / TradeLocker / DXTrade',
				'100% Profit Split',
				'Weekly Payouts',
			],
		},
		{
			title: 'MT5, TRADELOCKER, & DXTRADE',
			items: [
				'Experience Seamless Trading with MT5',
				'Advanced Charting on TradeLocker',
				'Customizable Interface with DXTrade',
			],
		},
		{
			title: 'SCALING',
			items: [
				'Merge Accounts',
				'$2M Scale Up Plan',
				'25% Increase Every 2 Months',
				'8% Profit to Qualify',
				'2 Consecutive Months Profitability',
			],
			extra: 'Gold and Platinum Levels',
		},
		{
			title: 'LEVERAGE ADVANTAGE',
			items: ['1:100 - FX', '1:50 - metals / indices', '1:5 - Crypto'],
		},
	]);
</script>


<style lang="scss" scoped>
	.section {
		@include padding-index;
	}

	/* Section title */
	.section-title {
		font-size: 5.1rem;

		@media (max-width: 768px) {
			font-size: 2rem;
		}
	}

	/* Grid Layout */
	.features-grid {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		/* 12-column grid */
		margin-top: 2rem;
		color: var(--nav-color);

		@media (max-width: 768px) {
			display: flex;
			overflow-x: auto;

			.feature-box {
				width: 18.75rem;
				flex-shrink: 0;
			}
		}
	}

	/* Feature Box Styles */
	.feature-box {
		padding: 2rem;
		height: 18.75rem;
		/* 300px -> rem */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
		/* 4px 8px -> rem */
		background-repeat: no-repeat;
		filter: grayscale(100%);
		background-size: cover;
		border: 0.0625rem solid var(--nav-color);
		/* 2px -> rem */
		transition: all 0.5s ease-in-out;

		h2 {
			font-size: 1.5rem;
			margin-bottom: 1rem;
			@include body-set(800);
		}

		ul {
			list-style: none;
			padding: 0;
			font-size: 0.8rem;
			margin: 0;

			li {
				margin-bottom: 0.25rem;
				position: relative;
				@include body-set();

				&::before {
					content: "";
					height: 0.8em;
					width: 0.8em;
					display: inline-block;
					margin-right: 0.5rem;
					vertical-align: middle;
					font-weight: bold;
					background: url('@/assets/index-arrow.webp');
					background-repeat: no-repeat;
					background-size: contain;
				}
			}
		}

		a {
			font-size: 0.75em;
			text-decoration: underline;
			font-weight: 800;
		}
	}

	/* Individual Feature Box Styles */
	.feature-box-1 {
		grid-column: span 4;
		/* 33% width */
		color: white;
		filter: grayscale(0);
		background-position-x: 20%;
	}

	.feature-box-2,
	.feature-box-3 {
		grid-column: span 8;
		/* 66% width */
	}

	.feature-box-4 {
		grid-column: span 4;
		/* 33% width */
	}

	.feature-box-5 {
		grid-column: span 12;
		/* 100% width */
	}

	/* Hover Effects */
	.feature-box:hover {
		color: white;
		filter: grayscale(0);
		background-position-x: 20%;
	}
</style>
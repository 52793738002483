<template>
    <div style="overflow-x: hidden;">
        <customerNav></customerNav>
        <Funding></Funding>
        <Know></Know>
        <values></values>
        <Traders></Traders>
        <customerBottom></customerBottom>
    </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
</style>
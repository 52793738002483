export function getImg(e) {
	// 使用相对路径的形式
	return require(`@/assets/${e}`);
}

export function go(e) {
	const router = this.$router

	if (e) {
		if (e.includes('#')) {
			router.push('/').then(r => {
				// 使用 a 标签定位跳转
				const anchor = document.querySelector(e);
				if (anchor) {
					anchor.scrollIntoView({
						behavior: 'smooth'
					});
				} else {
					console.warn(`Anchor ${e} not found`);
				}
			})
		} else if (e.includes('http')) {
			window.open(e)
		} else {
			// 使用 Vue Router 进行跳转
			router.push(e).catch(err => {
				if (err.name !== 'NavigationDuplicated') {
					console.error(err);
				}
			});
		}
	}
}
export function openLink(e) {
	if (e)
		window.open(e)
	else
		window.open(data.link)
}

export function transUpper(e) {
	if (e) {
		return e.toUpperCase()
	} else
		return ''
}
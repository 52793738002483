<template>
  <div class="succeeding-container">
    <h1 class="title" v-if="!isMobile">SUCCEEDING WITH FOREX <br>PROP TRADING FIRMS</h1>
    <h1 class="title" v-else>SUCCEEDING WITH <br> FOREX PROP TRADING FIRMS</h1>
    <div class="content">
      <div class="flex-box">
        <img :src="$getImg('Opportunities/1.1.webp')" />
        <p class="description">
          Proprietary trading in Forex involves minimal risk exposure, especially if you trade with robust skills, experience, and patience. While trading with funded capital, utilize your skills to make smart decisions and protect your accounts from high-risk events.
        </p>
      </div>
      <ul class="features-list">
        <li class="feature-item">
          <h3>FOLLOW TRADING MENTORS</h3>
          <p>
            Join an experienced community of professional traders to simplify skills acquisition and exploration of new markets. Ready to provide actionable insights to aspiring traders, our mentors empower traders with risk management tactics, market updates, and trading positions designed to harness the most profitable opportunities. Follow these prop trading mentors to deliver informed decisions—without breaching trading limits.
          </p>
        </li>
        <li class="feature-item">
          <h3>LEVERAGE EDUCATIONAL RESOURCES</h3>
          <p>
            Take advantage of comprehensive training programs, guides, and informative videos designed to support aspiring traders. At Funding Traders, you can utilize educational content, practical insights, and personalized guidance to maximize returns across diverse financial markets and positions. Participation in our training helps you acquire practical trading practices—regardless of whether you’re a new learner or not. Our resources allow access to Forex trading topics like account management, position sizing, and psychology practices.
          </p>
          <a href="#" class="link" @click="$openLink()">Gain up-to $2,000,000 in capital - Get Funded Now.</a>
        </li>
        <li class="feature-item">
          <h3>TRADE ON A FAMILIAR PLATFORM</h3>
          <p>
            With support for MetaTrader 5 (MT4/MT5), cTrader, and TradeStation, you have the flexibility to gain seamless access to familiar platforms, fully enhancing your trades. Along with helpful platforms that support diversified tactics, charting tools, and automation trading, Funding Traders is committed to structured, advanced, and comprehensive technical platforms to ensure efficiency while managing bigger positions—BeckTrader, MT4, or cTrader.
          </p>
        </li>
        <li class="feature-item">
          <h3>FOLLOW PROP TRADING RULES</h3>
          <p>
            To minimize risk exposure, improved rewards, and continuous supervision, follow standard proprietary trading guidelines. At Funding Traders, we equip all clients to maintain consistent risk positions, including a balanced approach across smart, safe, and critical topics. To maintain consistent profitability, traders can avoid high-risk strategies—much of it in artificial trading setups.
          </p>
        </li>
      </ul>
      <div class="image-section">
        <img :src="$getImg('eval.webp')" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store";
const isMobile = useStore().isMobile;
/* Static content, no logic required */
</script>

<style scoped lang="scss">
.succeeding-container {
  background-color: #000;
  color: #fff;
  @include padding-components;
  text-align: left;

  .title {
    font-size: 4.8rem;
    font-weight: bold;
    line-height:0.8;
    margin-bottom: 1.25rem;
  }
  .content {
    margin: 0 15%;
    border-top: 0.08rem solid #515151;
  }
  .description {
    font-size: 0.8rem;
    line-height: 1;
    color: #d7d7d7;
    font-weight: 500;
    margin:0;
  }

  .features-list {
    padding: 0;

    .feature-item {
      margin-bottom: 1.25rem;

      h3 {
        font-size: 0.8rem;
        @include body-set(800);
        margin-bottom: 0.625rem;
        color: #d7d7d7;
      }

      p {
        font-size: 0.8rem;
        line-height: 1;
        color: #8c8c8c;
      }

      .link {
        color: #bf350a;
        font-size: 0.8rem;

        &:hover {
          color: #ff7700;
        }
      }
    }
  }

  .image-section {
    display: flex;
    justify-content: center;
    margin-top: 1.875rem;
position: relative;
    img {
      max-width: 100%;
      height: auto;
      border-radius: 0.625rem;
      box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.8);
	  position:relative;
	  z-index: 1;
    }
  }
  .image-section::before{
				position: absolute;
				    top: -60%;
				    left: -10%;
				    right: 0;
				    height: 180%;
				    background-image: url('@/assets/background-glow.webp');
				    background-size: 100% 100%;
				    background-repeat: no-repeat;
				    content: " ";
			}
  .flex-box {
    display: flex;
    padding-top: 1rem;
    justify-content: space-between;
    align-items: center;

    img {
      width: 3.9rem;
      height: 3.9rem;
      flex-shrink: 0;
    }

    p {
      flex-basis: 70%;
      margin-left: 0.625rem;
    }
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    padding: 2rem 0.3125rem;
	.image-section::before{
		height:200% !important;
	}
    .title {
      font-size: 2.3rem;
    }

    .content {
      margin: 0 0.625rem;
    }

    .description {
      font-size: 0.9rem;
    }

    .features-list {
      .feature-item {
        h3 {
          font-size: 1.1rem;
        }

        p {
          font-size: 0.9rem;
        }

        .link {
          font-size: 0.9rem;
        }
      }
    }

    .flex-box {
      flex-direction: column;
      align-items: flex-start;

      img {
        width: 3.125rem;
        height: 3.125rem;
      }

      p {
        margin-left: 0;
        margin-top: 1rem;
        text-align: left;
      }
    }

    .image-section img {
      max-width: 100%;
      width: 100%;
    }
  }
}

</style>

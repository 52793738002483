<template>
  <RouterView></RouterView>
</template>

<script setup>
import { useStore } from "./store";
(() => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /iphone|ipod|ipad|android|blackberry|iemobile|opera mini|mobile/i.test(
    userAgent
  );
  if (!isMobile) {
    useStore().setisMobile(false);
  } else {
    useStore().setisMobile(true);
  }
})();
</script>

<style lang="scss">
body {
  background: black;
  color: var(--themeColor);
}
</style>

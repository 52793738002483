<template>
  <div class="payout-system" id="benefits">
    <h1 class="title">PAYOUT SYSTEM</h1>

    <div class="grid-container">
      <!-- Profit Split Section -->
      <div class="grid-item">
        <h2>PROFIT SPLIT</h2>
        <ul>
          <li>
            <span class="highlight">80%</span>
            <span>Default</span>
          </li>
          <li>
            <span class="highlight">90%</span>
            <span>+15% add-on</span>
          </li>
          <li>
            <span class="highlight">100%</span>
            <span>+30% add-on</span>
          </li>
        </ul>
        <p class="description">
          By adopting a system that allows us to copy successful traders,
          ensuring a win-win situation for all.
        </p>
      </div>

      <!-- Period Section -->
      <div class="grid-item">
        <h2>PERIOD</h2>
        <ul>
          <li>
            <span>14-day payouts</span>
            <span class="highlight">Default</span>
          </li>
          <li>
            <span>7-day payouts*</span>
            <span class="highlight">+25% add-on</span>
          </li>
        </ul>
      </div>

      <!-- Options Section -->
      <div class="grid-item">
        <h2>OPTIONS</h2>
        <ul>
          <li>
            <span>Bank Transfer (Rise)</span>
            <span class="highlight">1-3 Business Days</span>
          </li>
          <li>
            <span>Crypto</span>
            <span class="highlight">Instant</span>
          </li>
        </ul>
        <div class="options-icons">
          <img :src="$getImg('logo-binance.webp')" alt="Binance" />
          <img :src="$getImg('logo-coinbase.webp')" alt="Coinbase" />
        </div>
      </div>
    </div>

    <!-- Footer Section -->
    <div class="footer">
      <h2>PAYOUTS IN 48 HRS OR WE PAY EXTRA $1000*</h2>
      <p>
        * only for transfers via crypto; when transferring through a bank, we
        send money within 24 hours, but the bank delay may be 2-3 days <br />
        * only when cleared by risk management team
      </p>
      <div class="btn">
        <button class="get-funded" @click="$openLink()">
          GET FUNDED
          <img :src="$getImg('icon-cta-arrow-black.webp')" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
/* Root styles */
.payout-system {
  background-color: #bf350a;
  color: white;
  padding: 6rem 1rem 12rem;
  font-family: Arial, sans-serif;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
}

.title {
  font-size: 3.8rem;
  margin-bottom: 2rem;
  color: black;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    font-weight: 800;
  }
}

/* Grid layout */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Single column layout on mobile */
    gap: 0; /* Smaller gap for mobile */
  }
}

.grid-item {
  background: #000;
  padding: 0.8rem;
  // border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  position: relative;
  color: white;
  @media (max-width: 768px) {
    border-radius: 0;
  }
}

.grid-item h2 {
  font-size: 2rem;
  @include body-set();
  margin:0;
  margin-bottom: 1rem;
}

.grid-item ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.grid-item ul li {
  display: flex;
  justify-content: space-between;
  border-top: 0.0625rem solid var(--nav-color);
  padding: 0.25rem 0;
  color: #8c8c8c;
  font-size:0.8rem;
}

.grid-item ul li:nth-last-of-type(1) {
  border-bottom: 0.0625rem solid #8c8c8c;
}

.grid-item p.description {
  font-size: 0.6rem;
  text-indent:5rem;
  margin-top: 1rem;
  color: #8c8c8c;
}

/* Options icons */
.options-icons {
  display: flex;
  position: absolute;
  left: 0.8rem;
  bottom: 1.5rem;

  @media (max-width: 768px) {
    position: static;
    justify-content: center;
    margin-top: 1rem;
  }
}

.options-icons img {
  width: 5rem;
  height: auto;
  margin-right: 0.5rem;
}

.highlight {
  color: white;
}

/* Footer styles */
.footer {
  margin-top: 2rem;
  border-top: 0.08rem solid #515151;
  padding-top: 1rem;
  display: flex;
  flex-direction: column; /* Stack footer content on mobile */

  @media (min-width: 769px) {
    flex-direction: row;
    // align-items: center;
  }
}

.footer h2 {
  font-size: 3.125rem;
  display: none;
  margin: 0 0 1rem;
  flex-basis: 100%;
  color: black;
  @include body-set();
  @media (min-width: 769px) {
    flex-basis: 30%;
    margin: 0;
    // padding-right: 10%;
  }
}

.footer p {
  font-size: 0.9rem;
  margin: 0;
  display: none;
  color: #000;
  flex-basis: 100%;
  @media (min-width: 769px) {
    flex-basis: 23%;
    padding: 0 10%;
  }
}

.btn {
  text-align: center;
  margin-top: 1rem;
  @media (min-width: 769px) {
    flex:1;
    text-align: right;
    margin-top: 0;
  }
}

.get-funded {
  background: #000;
  color: white;
  @include body-set(800);
  padding: 0.3rem 3rem;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
  font-size: 0.8rem;
  @media (max-width: 769px) {
    font-weight: 600;
    width: 100%;
  }
}

.get-funded img {
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 0.3rem;
  filter: brightness(100);
}
</style>

import axios from 'axios';

// 创建 axios 实例
const service = axios.create({
  baseURL: '', // 基础请求地址
  timeout: 5000, // 超时时间
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理，比如添加 Token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 直接返回响应数据
    const res = response.data;
    // 可根据后端返回的状态码进行自定义处理
    if (res.code !== 200) {
      console.error('Error:', res.message || 'Error');
      return Promise.reject(new Error(res.message || 'Error'));
    }
    return res;
  },
  (error) => {
    // 响应错误处理
    console.error('Response error:', error);
    return Promise.reject(error);
  }
);

export default service;

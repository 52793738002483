<template>
    <div class="Partner">
        <div class="title">
            {{ $trans('Partner Care Hub') }}
        </div>
        <div class="content">
            <el-collapse v-model="activeName" accordion>
      <el-collapse-item
        :name="index"
        v-for="(item, index) in table"
        :key="index"
      >
        <template #title>
          <div class="tab">
            <div class="a">
              <span>{{ index+1 }}</span>
            </div>
            <div class="b">
              <span>{{ $trans(item.label2) }}</span>
            </div>
          </div>
        </template>
        <template #icon="{ isActive }">
          <img
            :src="isActive ? $getImg('icon-eval-arrow_rotated.webp') : $getImg('icon-eval-arrow.webp')"
            class="tabimg"
          />
        </template>
        <div v-html="item.content" class="content"></div>
      </el-collapse-item>
    </el-collapse>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from "vue";

const activeName = ref('1');
const table = reactive([
  {
    label2: "Affiliate Kit - Everything You Need for High Sales",
    content: `  <div>
    <ul style="margin-top: 1rem; line-height: 1.8;">
      <li>Opening Phrases</li>
      <li>The Benefits</li>
      <li>Info Resources</li>
      <li>Plans Descriptions</li>
      <li>Banners and Images</li>
    </ul>
    <img src="../../about/banner1.webp" style="width:9.375rem;"/>
    <img src="../../about/banner.webp" style="width:9.375rem;"/>
    <img src="../../about/block.webp" style="width:9.375rem;"/>
  </div>`
  },
  {
    label2: "Real-Time Tracking",
    content: `
  <div>
    <p>
      Track your clicks, registrations and conversions in real-time to stay up-to-date with your progress. You can also check your payment and withdrawal history easily in your dashboard.
    </p>
  </div>
`
  },
  {
    label2: "Mentor in Affiliate Marketing",
    content: `
  <div>
    <p>
      You will be accompanied by a personal manager who will teach you how to do marketing correctly
    </p>
  </div>
`
  },
  {
    label2: "Easy & Fast Withdrawals",
    content: `
  <div>
    <p>
     Submit your withdrawal request from the dashboard and collect your commissions instantly.
    </p>
  </div>
`
  },
  {
    label2: "High Conversion Rate",
    content: `
  <div>
    <p>
      Our website and business model generate one of the highest conversion rates in the industry. Our high rates mean that the people who you refer to us are more likely to buy our products. If a customer comes from your site to ours and then comes back to purchase in 90 days, you get credit.
    </p>
  </div>
`
  }
]);
</script>

<style lang="scss" scoped>
.Partner {
  @include padding-components;
  display: flex;
  .title {
    flex-basis: 30%;
    font-size: 4.8rem;
    font-weight: 800;
    line-height:0.9;
    @include title-set;
  }
  .content {
    flex: 1;
  }
}
.tab {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding:1rem 0;
    .a {
      flex-basis: 6%;
      text-align: left;
      color: #d7d7d7;
      display: flex;
      justify-content: space-between;

      span {
        border-radius: 50%;
        border: 0.0625rem solid #d7d7d7;
        width: 1rem;
        height: 1rem;
        font-size: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .b {
      flex: 1;
      display: flex;
      justify-content: space-between;

      span {
        text-align: left;
        color: white;
        font-size: 2rem;
        font-weight: 500;
      }
    }
  }

  ::v-deep .el-collapse {
    flex: 1;
    border-bottom: 0;
    border-color:var(--dark-grey)
  }

  ::v-deep .el-collapse-item__header {
    background: transparent;
    color: var(--nav-color);
    font-size: 1rem;
    height:auto;
    border-color:var(--dark-grey)
  }

  ::v-deep .el-collapse-item__wrap {
    background: transparent;
    color: var(--nav-color);
  }

  ::v-deep .el-collapse-item__content {
    color: inherit;
  }

  @media (max-width: 768px) {
    .Partner{
     @include mobile-padding-components;
      flex-direction:column;
      .title{
        font-size:2.5rem;
      }
    }
    ::v-deep .el-collapse-item__header {
      height: auto;
      padding: 1rem 0;
    }

    ::v-deep .el-collapse {
      border-top: none;

      img {
        width: 50%;
      }
    }

    .tab {
      flex-direction: row;

      .a {
        color: white;
        font-size: 1.2rem;
      }

      .b {
        flex-basis: 100%;
        text-align: left;
        line-height: 1;
      }

      .b span {
        font-size: 1.5rem;
        padding:0 10%;
      }
    }

    .tabimg {
      width: 0.8rem !important;
    }
    .content{
      font-size:0.8rem;
    }
  }
  .tabimg {
      width: 0.6rem;
    }
</style>
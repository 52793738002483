<template>
<div class="traders">
  <h1>{{ $trans('Join a Team of Funding Traders') }}</h1>
  <div class="Traders">
    <div class="left">
      <span>with extensive experience in institutional organizations</span>
    </div>
    <div class="right">
      <ul>
        <li v-for="(item, index) in step" :key="index">
          <span class="item">{{ index + 1 }}</span>
          <span class="content">{{ $trans(item) }}</span>
        </li>
      </ul>
      <subBtn text="Start Challenge" marginTop="0"></subBtn>
	  <div style="height:10rem;"></div>
    </div>
    <video
      class="background-video"
      :src="$getImg('fire-2.mp4')"
      autoplay="autoplay"
      loop="loop"
      muted="muted"
      preload="auto"
      playsinline="playsinline"
    ></video>
  </div>
</div>
</template>

<script setup>
const step = [
  "Support 24/7, Mentorship from CEO",
  "Best Trade Rules",
  "Scaling Plan Up To $2m",
  "100% Payouts in 7 Days(When Using Addons)",
  "Monthly Competition and Prizes"
];
</script>

<style lang="scss" scoped>
.traders{
  @include padding-index;
}
h1 {
  font-size: 4.8rem;
  width: 50%;
  line-height:0.8
}
@media (max-width: 768px) {
    h1 {
      font-size: 2rem;
      width:100% !important;
    }
  }
.Traders {
  display: flex;
  position: relative;

  .left {
    flex-basis: 50%;

    span {
      font-size: 0.6rem;
      color: #fff;
    }
  }

  .right {
    flex: 1;
	max-height: 20rem;
	overflow-y: auto;
	mask-image: linear-gradient(to bottom, transparent 0%, var(--white) 10%, var(--white) 20%, transparent 80%);
  }
  .right::-webkit-scrollbar{
	  display:none;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 1.75rem 0.625rem;
      display: flex;
      align-items: flex-start;

      .item {
        border-radius: 50%;
        border: 0.0625rem solid white;
        width: 0.8rem;
        height: 0.8rem;
        font-size: 0.6rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        transform: translateY(25%);
      }

      .content {
        font-size: 2rem;
        padding-left: 1.5rem;
        line-height: 0.9;
      }
    }
  }

  .background-video {
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: -1.125rem;
    z-index: -1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    .left {
      margin-bottom: 2rem;

      span {
        font-size: 1rem;
      }
    }

    .right {
      ul {
        li {
          padding: 1rem 0;

          .item {
            width: 2rem;
            height: 2rem;
            font-size: 1rem;
          }

          .content {
            font-size: 1.5rem;
          }
        }
      }

      .subBtn {
        width: 100%;
        margin-top: 2rem;
      }
    }
  }
}
</style>

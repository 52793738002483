<template>
  <div class="values">
    <div class="title">
      OUR VALUES
    </div>
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item :name="index" v-for="(item, index) in table" :key="index">
        <template #title>
          <div class="tab">
            <div class="a">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="b">
              <span>{{ $trans(item.label2) }}</span>
            </div>
          </div>
        </template>
        <template #icon="{ isActive }">
          <img :src="isActive ? $getImg('icon-eval-arrow_rotated.webp') : $getImg('icon-eval-arrow.webp')"
            class="tabimg" />
        </template>
        <div v-html="item.content" class="content"></div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script setup>
  import { reactive, ref } from "vue";

  const activeName = ref();
  const table = reactive([
    {
      label2: "Gradual Growth",
      content: `  <div>
    <p>
      We adhere to the principle that there should be gradual growth, therefore our rules are aimed at forming a disciplined trader, and not someone who withdraws 1 jackpot once, as in a casino and only loses after.
    </p>
    <p>
      It is safer for the psyche, as each level of capital has its own characteristics. You need time to gain a foothold on each and develop your own strategy there.
    </p>
    <blockquote style="font-size: 2rem; font-weight: bold;margin:0; margin-top: 2rem; color: #b0b0b0;">
      "The biggest deception in Instagram advertising now is that they will teach you in two months, in reality it takes from 1 to 3 years."
    </blockquote>
    <p style="margin-top: 1rem;">Stan G.</p>
    <img src="../../about/block.webp" />
  </div>`
    },
    {
      label2: "Support",
      content: `
  <div>
    <p>
      Support and training, transfer of experience. We care about you. We want you to survive in this environment and grow up and earn money for yourself and us. We don't want young and talented traders to burn out before they can start.
    </p>
    <ul style="margin-top: 1rem; line-height: 1.8;">
      <li>Share Screens</li>
      <li>Free Classes</li>
      <li>All Needed Apps</li>
      <li>24/7 Support</li>
      <li>AI Helpers</li>
    </ul>
  </div>
`
    },
    {
      label2: "Exclusivity",
      content: `
  <div>
    <p>
      Trading is not for everyone, only 5% will be able to. This is a closed special world, we create a desire to be among these 5%, the same strong spirit, risky, cool, with iron balls, cold mind.
    </p>
    <p>
      A small number of people earn a lot of money.
    </p>
    <img src="../../about/block.webp" />
    <img src="../../about/block.webp" />
  </div>
`
    },
    {
      label2: "Freedom",
      content: `
  <div>
    <p>
      Not to be slaves to systems, politics, to get out of the matrix, at a fairly early age, to choose for yourself, to be independent.
    </p>
    <p>
      No country restrictions
    </p>
    <p>
      The company is located in Dubai
    </p>
  </div>
`
    },
    {
      label2: "Real Exp",
      content: `
  <div>
    <h1>Mentorship from CEO - ex-Wall Street Trader</h1>
    <p>
      Supportive community where experienced traders and mentors guide and share insights with the less experienced.
    </p>
    <h1>Forex Education</h1>
    <p>
      From novice to experienced, our educational resources aim to enhance their market understanding, trading skills, and risk management strategies.
    </p>
  </div>
`
    },
    {
      label2: "Self-Mastery",
      content: `  <div>
    <p>
      Meeting with the market, you get to know yourself, your fears, your weaknesses, in order to be successful, you need to master yourself, your thoughts and emotions, your energy, develop discipline, defeat yourself.
    </p>
    <blockquote style="font-size: 2rem; font-weight: bold;margin:0; margin-top: 2rem; color: #b0b0b0;">
      "We are looking for diamonds in the rough"
    </blockquote>
    <p style="margin-top: 1rem;">Stan G.</p>
    <img src="../../about/video.png" />
  </div>`
    }
  ]);
</script>

<style lang="scss" scoped>
  .values {
    display: flex;
    @include padding-components;

    .title {
      font-size: 5rem;
      flex-basis: 30%;
      line-height: 0.9;
      @include title-set;
    }
  }

  @media (max-width: 768px) {
    .values {
      padding: 2rem 0.5rem;
      flex-direction: column;
      gap: 1rem;

      .title {
        font-size: 2rem;
      }
    }
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    .a {
      flex-basis: 6%;
      text-align: left;
      color: #d7d7d7;
      display: flex;
      justify-content: space-between;

      span {
        border-radius: 50%;
        border: 0.0625rem solid #d7d7d7;
        width: 1rem;
        height: 1rem;
        font-size: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
      }
    }

    .b {
      flex: 1;
      display: flex;
      justify-content: space-between;

      span {
        text-align: left;
        color: white;
        font-size: 2rem;
      }
    }
  }
  .tabimg {
        width: 0.6rem;
      }

  ::v-deep .el-collapse {
    flex: 1;
    border-bottom: 0;
  }

  ::v-deep .el-collapse-item__header {
    background: transparent;
    color: var(--nav-color);
    font-size: 1rem;
    height: auto;
    padding: 1rem 0;
  }

  ::v-deep .el-collapse-item__wrap {
    background: transparent;
    color: var(--nav-color);
  }

  ::v-deep .el-collapse-item__content {
    color: inherit;
  }

  @media (max-width: 768px) {
    ::v-deep .el-collapse-item__header {
      height: auto;
    }

    ::v-deep .el-collapse {
      border-top: none;

      img {
        width: 50%;
      }
    }

    .tab {
      flex-direction: row;
      align-items: center;

      .a {
        color: #8c8c8c;
        font-size: 1.2rem;
      }

      .b {
        flex-basis: 100%;
        text-align: left;
      }

      .b span {
        font-size: 1.2rem;
		padding:0 0.5rem;
      }
    }

    .tabimg {
      width: 1rem !important;
    }
  }
  .content{
    font-size:0.8rem;
  }
</style>